import produce from "immer";
import { ISicavModel, ISicavSummaryModel, IGetSicavModel, IGetSicavsModel, EntitySummaryModel, CustomScreenModel, CustomScreenSummaryModel, ICustomScreenDataModel, IGetCustomScreensDataModel, ISecuritiesSubmitCustomScreenDataParameters, IMonitoringResultsetModel, IMacroScriptsGetMonitoringResultForTargetParameters, IGetProcessExecutionsModel, ProcessExecutionSummaryModel, IGetDocumentsModel, IDocumentSummaryModel } from "proxy/apiProxy";
import { produceActionFactories, AnyActionOf } from "tools/lib/store";
import { IGetEntitySummary, IGetRelationshipSummary } from "../Reference/slice";
import { IBase64File } from "tools/lib/utility";
import { GetAllSummaries } from "features/Document/utils";
export interface ISicavData {
    sicav: ISicavModel;
    imageFile?: IBase64File;
}
export interface IState {
    allLoading: boolean;
    loading: boolean;
    all: (ISicavSummaryModel | ISicavModel)[];
    current?: ISicavModel;
    saving: boolean;
    deleting: boolean;
    dictionaries: GetAllSummaries & {
        entities: Record<EntitySummaryModel["id"], EntitySummaryModel>;
        customScreens: Record<string | number, CustomScreenSummaryModel>;
    }
    entityPicture?: IBase64File;
    customScreens?: CustomScreenModel[];
    customScreenDatas?: ICustomScreenDataModel[];
    customScreenDataSubmitting: boolean;
    customScreenDataLoading: boolean;
    monitoringResults: Record<number, IMonitoringResultsetModel | null>;
    processExecutions?: ProcessExecutionSummaryModel[];
    processExecutionsLoading: boolean;

    documentsLoading: boolean;
    documents?: IDocumentSummaryModel[];
}
export interface ISicavLoadedPayload extends IGetSicavModel {
    entityPicture?: IBase64File;
}

export const ActionFactories = produceActionFactories({
    sicavLoad: (payload: ISicavModel["id"]) => payload,
    sicavLoaded: (payload: ISicavLoadedPayload) => payload,
    sicavSave: (payload: ISicavData) => payload,
    sicavSaved: (payload: ISicavModel) => payload,
    sicavDelete: (payload: ISicavModel["id"]) => payload,
    sicavDeleted: (payload: ISicavModel["id"]) => payload,
    sicavLoadSicavs: (payload: boolean) => payload,
    sicavLoadAll: () => undefined,
    sicavLoadedAll: (payload: IGetSicavsModel) => payload,
    sicavAddEntityInDictionary: (payload: IGetEntitySummary) => payload,
    sicavLoadedRelationship: (payload: IGetRelationshipSummary) => payload,

    sicavCustomScreensLoaded: (payload: CustomScreenModel[]) => payload,
    sicavCustomScreenDatasLoad: (payload: number) => payload,
    sicavCustomScreenDatasLoaded: (payload: IGetCustomScreensDataModel) => payload,
    sicavCustomScreenDataSubmit: (payload: ISecuritiesSubmitCustomScreenDataParameters) => payload,
    sicavCustomScreenDataSubmitted: (payload: ICustomScreenDataModel) => payload,

    sicavMonitoringResultLoad: (payload: IMacroScriptsGetMonitoringResultForTargetParameters) => payload,
    sicavMonitoringResultLoaded: (payload: IMonitoringResultsetModel) => payload,
    sicavProcessExecutionsLoad: (payload: number) => payload,
    sicavProcessExecutionsLoaded: (payload: IGetProcessExecutionsModel) => payload,

    sicavDocumentsLoad: (payload: number) => payload,
    sicavDocumentsLoaded: (payload: IGetDocumentsModel) => payload,
});

export function reducer(
    state: IState = {
        loading: false,
        allLoading: false,
        saving: false,
        deleting: false,
        all: [],
        dictionaries: {
            entities: {},
            relationships: {},
            customScreens: {},
            securities: {},
            portfolios: {},
            cashMovements: {},
            transactions: {},
        },

        customScreenDataLoading: false,
        customScreenDataSubmitting: false,
        monitoringResults: {},
        processExecutionsLoading: false,
        documentsLoading: false,
    },
    action: AnyActionOf<typeof ActionFactories>
): IState {
    return produce(state, draft => {
        switch (action.type) {
            case "sicavProcessExecutionsLoad":
                draft.processExecutionsLoading = true;
                break;
            case "sicavProcessExecutionsLoaded":
                draft.processExecutionsLoading = false;
                draft.processExecutions = action.payload.processExecutions;
                draft.dictionaries.entities = { ...draft.dictionaries.entities, ...action.payload.entities };
                draft.dictionaries.relationships = { ...draft.dictionaries.relationships, ...action.payload.relationships };
                break;

            case "sicavDocumentsLoad":
                draft.documentsLoading = true;
                break;
            case "sicavDocumentsLoaded":
                draft.documentsLoading = false;
                draft.documents = action.payload.documents;
                draft.dictionaries.entities = { ...draft.dictionaries.entities, ...action.payload.entities };
                draft.dictionaries.securities = { ...draft.dictionaries.securities, ...action.payload.securities };
                draft.dictionaries.relationships = { ...draft.dictionaries.relationships, ...action.payload.relationships };
                draft.dictionaries.cashMovements = { ...draft.dictionaries.cashMovements, ...action.payload.cashMovements };
                draft.dictionaries.transactions = { ...draft.dictionaries.transactions, ...action.payload.transactions };
                draft.dictionaries.portfolios = { ...draft.dictionaries.portfolios, ...action.payload.portfolios };
                break;

            case "sicavMonitoringResultLoad":
                draft.monitoringResults[action.payload.id] = null;
                break;
            case "sicavMonitoringResultLoaded":
                draft.monitoringResults[action.payload.monitoringMacroId] = action.payload;
                break;


            case "sicavCustomScreensLoaded":
                draft.customScreens = action.payload;
                break;
            case "sicavCustomScreenDatasLoad":
                draft.customScreenDataLoading = true;
                break;
            case "sicavCustomScreenDatasLoaded":
                draft.customScreenDataLoading = false;
                draft.dictionaries.entities = { ...draft.dictionaries.entities, ...action.payload.entities };
                draft.dictionaries.customScreens = { ...draft.dictionaries.customScreens, ...action.payload.customScreens };
                draft.customScreenDatas = action.payload.customScreenDatas;
                break;
            case "sicavCustomScreenDataSubmit":
                draft.customScreenDataSubmitting = true;
                break;
            case "sicavCustomScreenDataSubmitted":
                if (!draft.customScreenDatas) {
                    draft.customScreenDatas = [];
                }
                const customScreenData = draft.customScreenDatas.find(i => i.customScreenId === action.payload.customScreenId);
                if (!!customScreenData) {
                    Object.assign(customScreenData, action.payload);
                }
                else {
                    draft.customScreenDatas.push(action.payload);
                }
                draft.customScreenDataSubmitting = false;
                break;

            case "sicavLoadedRelationship":
                draft.dictionaries.relationships[action.payload.relationship.id] = action.payload.relationship;
                draft.dictionaries.entities = { ...draft.dictionaries.entities, ...action.payload.entities };
                draft.dictionaries.relationships = { ...draft.dictionaries.relationships, ...action.payload.relationships };
                // draft.dictionaries.processDefinitions = { ...draft.dictionaries.processDefinitions, ...action.payload.processDefinitions };
                break;
            case "sicavAddEntityInDictionary":
                draft.dictionaries.entities[action.payload.entity.id] = action.payload.entity;
                draft.dictionaries.entities = { ...draft.dictionaries.entities, ...action.payload.entities };
                break;
            case "sicavLoadAll":
            case "sicavLoadSicavs":
                draft.allLoading = true;
                break;
            case "sicavLoadedAll":
                {
                    draft.allLoading = false;
                    const { all, ...dictionaries } = action.payload;
                    draft.all = all;
                    draft.dictionaries = { ...draft.dictionaries, ...dictionaries };
                }
                break;
            case "sicavLoad":
                draft.customScreens = undefined;
                draft.customScreenDatas = undefined;
                draft.loading = true;
                draft.current = undefined;
                // draft.relatedProcesses = undefined;
                // draft.checksDueDiligence = undefined;
                // draft.complianceDueDiligence = undefined;
                draft.entityPicture = undefined;
                draft.monitoringResults = {};
                draft.documents = undefined;
                draft.processExecutions = undefined;
                break;
            case "sicavLoaded":
                {
                    draft.loading = false;
                    const { sicav, entityPicture, ...dictionaries } = action.payload;
                    draft.current = sicav;
                    draft.entityPicture = entityPicture;
                    draft.dictionaries.entities = { ...draft.dictionaries.entities, ...dictionaries.entities };
                }
                break;
            case "sicavSave":
                draft.saving = true;
                break;
            case "sicavSaved":
                draft.saving = false;
                const saved = action.payload;
                if (draft.current) {
                    draft.current = saved;
                }
                const existing = draft.all.find(i => i.id === saved.id);
                if (existing) {
                    Object.assign(existing, saved);
                }
                else {
                    draft.all.push(saved);
                }
                break;
            case "sicavDelete":
                draft.deleting = true;
                break;
            case "sicavDeleted":
                draft.deleting = false;
                const deletedId = action.payload;
                if (draft.current?.id === deletedId) {
                    delete draft.current;
                }
                const idx = draft.all.findIndex(i => i.id === deletedId);
                if (idx >= 0) {
                    draft.all.splice(idx, 1);
                }
                break;

            // // <DUE DIL MANAGEMENT>
            // case "sicavComplianceDueDiligenceLoaded":
            //     draft.complianceDueDiligence = {
            //         execution: action.payload?.dueDiligenceExecution,
            //         fileLoading: {},
            //         fileSaving: {}
            //     };
            //     if (action.payload?.dueDiligenceExecution) {
            //         draft.dictionaries.entities = { ...draft.dictionaries.entities, ...action.payload.entities };
            //         draft.dictionaries.processDefinitions = { ...draft.dictionaries.processDefinitions, ...action.payload.processDefinitions };
            //         draft.dictionaries.relationships = { ...draft.dictionaries.relationships, ...action.payload.relationships };
            //     }
            //     break;
            // case "sicavChecksDueDiligenceLoaded":
            //     draft.checksDueDiligence = {
            //         execution: action.payload?.dueDiligenceExecution,
            //         fileLoading: {},
            //         fileSaving: {}
            //     };
            //     if (action.payload?.dueDiligenceExecution) {
            //         draft.dictionaries.entities = { ...draft.dictionaries.entities, ...action.payload.entities };
            //         draft.dictionaries.processDefinitions = { ...draft.dictionaries.processDefinitions, ...action.payload.processDefinitions };
            //         draft.dictionaries.relationships = { ...draft.dictionaries.relationships, ...action.payload.relationships };
            //     }
            //     break;
            // case "sicavLoadChecksFile":
            //     if (draft.checksDueDiligence) {
            //         draft.checksDueDiligence.fileLoading[action.payload.taskCode] = true;
            //     }
            //     break;
            // case "sicavLoadedChecksFile":
            //     if (draft.checksDueDiligence) {
            //         delete draft.checksDueDiligence.fileLoading[action.payload.taskCode];
            //     }
            //     break;
            // case "sicavSaveChecksFile":
            //     if (draft.checksDueDiligence) {
            //         draft.checksDueDiligence.fileSaving[action.payload] = true;
            //     }
            //     break;
            // case "sicavSavedChecksFile":
            //     if (draft.checksDueDiligence) {
            //         delete draft.checksDueDiligence.fileSaving[action.payload];
            //     }
            //     break;
            // case "sicavLoadComplianceFile":
            //     if (draft.complianceDueDiligence) {
            //         draft.complianceDueDiligence.fileLoading[action.payload.taskCode] = true;
            //     }
            //     break;
            // case "sicavLoadedComplianceFile":
            //     if (draft.complianceDueDiligence) {
            //         delete draft.complianceDueDiligence.fileLoading[action.payload.taskCode];
            //     }
            //     break;
            // case "sicavSaveComplianceFile":
            //     if (draft.complianceDueDiligence) {
            //         draft.complianceDueDiligence.fileSaving[action.payload] = true;
            //     }
            //     break;
            // case "sicavSavedComplianceFile":
            //     if (draft.complianceDueDiligence) {
            //         delete draft.complianceDueDiligence.fileSaving[action.payload];
            //     }
            //     break;
            // case "sicavLoadedProcesses":
            //     draft.processesLoading = false;
            //     draft.relatedProcesses = action.payload;
            //     break;
            // case "sicavLoadProcesses":
            //     draft.processesLoading = true;
            //     break;
            // // </DUE DIL MANAGEMENT>
        }
    });
}
