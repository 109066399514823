import { useCallback, useMemo, useState } from "react";
import { Breadcrumbs, Icon, makeStyles, Typography } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Toolbar from '@material-ui/core/Toolbar';
import MenuIcon from '@material-ui/icons/Menu';
import UserDropdown from 'tools/components/UserDropdown';
import { useReduxActions, useReduxSelections } from 'tools/lib/reduxStoreAccess';
import AppSearchBar from './AppSearchBar';
import { getUserManager } from "lib/userManager";
import clsx from "clsx";
import { ScreenLink } from "tools/routing/ScreenLink";
import TenantsDialog from "../../../layout/TenantsDialog";
import { useMenu } from "../menuTools";

const drawerWidth = 300;

const useStyles = makeStyles(theme => ({
    appBar: {
        transition: theme.transitions.create(['width', 'margin'], {
            duration: theme.transitions.duration.leavingScreen,
            easing: theme.transitions.easing.sharp,
        }),
        zIndex: theme.zIndex.drawer + 1,
    },
    toolBar: {
        "& > *:not(:first-child)": {
            marginLeft: theme.spacing(1)
        }
    },
    appBarShift: {
        marginLeft: drawerWidth,
        transition: theme.transitions.create(['width', 'margin'], {
            duration: theme.transitions.duration.enteringScreen,
            easing: theme.transitions.easing.sharp,
        }),
        width: `calc(100% - ${drawerWidth}px)`,
    },
    hide: {
        display: 'none',
    },
    menuButton: {
        marginLeft: 12,
        marginRight: 36,
    },
    insideToolbarFiller: {
        flexGrow: 1,
    },
}));

function useAccessibleTenants() {
    const { accessibleTenants = [], currentTenant } = useReduxSelections("app")
    return useMemo(() => accessibleTenants.filter(t => t.id !== currentTenant?.id).sort((a, b) => a.name > b.name ? 1 : b.name > a.name ? -1 : 0), [accessibleTenants, currentTenant?.id]);
}

export default function ApplicationBar() {
    const {
        menuDrawerOpened,
        currentUser,
        tenantsImageUrls
    } = useReduxSelections("app")
    const tenants = useAccessibleTenants()
    const { openMenuDrawer, switchTenant, tenantsImageLoad } = useReduxActions("app");
    const handleRequestLogout = useCallback(() => {
        const userManager = getUserManager();
        userManager.signoutRedirect()
    }, [])
    const [tenantsOpened, setTenantsOpened] = useState(false);
    const handleOpenChangeTenant = useCallback(() => {
        setTenantsOpened(true);
        tenantsImageLoad();
    }, [tenantsImageLoad]);
    const handleCloseChangeTenant = useCallback(() => setTenantsOpened(false), []);
    const handleTenantSelected = (tenantId: number) => {
        setTenantsOpened(false);
        switchTenant(tenantId);
    }
    const classes = useStyles();
    return <>
        <AppBar position="fixed" className={clsx(classes.appBar, { [classes.appBarShift]: menuDrawerOpened })}>
            <Toolbar className={classes.toolBar} disableGutters={!menuDrawerOpened}>
                <IconButton
                    color="inherit"
                    aria-label="Open drawer"
                    onClick={openMenuDrawer}
                    className={clsx(classes.menuButton, {
                        [classes.hide]: menuDrawerOpened,
                    })}>
                    <MenuIcon />
                </IconButton>
                <ActiveScreenTitle showParent={!menuDrawerOpened} />
                <TopMenuTabs />
                <div className={classes.insideToolbarFiller} />
                <AppSearchBar />
                {!!currentUser &&
                    <UserDropdown
                        user={currentUser}
                        onLogout={handleRequestLogout}
                        onChangeTenant={(tenants.length > 0) ? handleOpenChangeTenant : undefined}
                    />}
            </Toolbar>
        </AppBar>
        <TenantsDialog accessibleTenants={tenants} opened={tenantsOpened} onCancel={handleCloseChangeTenant}
            onTenantSelected={handleTenantSelected} tenantsImageUrls={tenantsImageUrls} />
    </>
}

const useNavigationModuleTitleStyles = makeStyles(theme => ({
    link: {
        display: 'flex',
        alignItems: 'center',
    },
    icon: {
        marginRight: theme.spacing(0.5),
    },
}));
interface IActiveScreenTitleProps {
    showParent: boolean
}
function ActiveScreenTitle({ showParent }: IActiveScreenTitleProps) {
    const { currentModule, currentCategory } = useMenu();
    const classes = useNavigationModuleTitleStyles()
    if (!currentModule || !currentCategory) return null

    return <Breadcrumbs separator="›" color="textPrimary">
        {showParent && <Typography variant="h6" noWrap>{currentCategory.label}</Typography>}
        <Typography variant="h6" noWrap className={classes.link}>
            <Icon className={classes.icon}>{currentModule.icon}</Icon>
            {currentModule.label}
        </Typography>
    </Breadcrumbs>
}
const useScreenTabStyles = makeStyles(theme => ({
    tabButton: {
        minHeight: 64,
    }
}));
function TopMenuTabs() {
    const classes = useScreenTabStyles();
    const { currentScreen, currentModule } = useMenu();

    if (!currentModule?.screens?.length) return null

    return <Tabs value={currentScreen?.parent?.label ?? currentScreen?.label}>
        {currentModule.screens.map((screen, idx) => <Tab
            key={idx}
            className={classes.tabButton}
            label={screen.label}
            value={screen.label}
            component={ScreenLink}
            destination={screen} />)}
    </Tabs>
}
