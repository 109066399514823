import produce from "immer";
import {
    CustomScreenModel,
    CustomScreenSummaryModel,
    EntityModel,
    EntitySummaryModel,
    ICustomScreenDataModel,
    IDocumentSummaryModel,
    IEntitiesSearchParameters,
    IEntityPositionModel,
    IEntityTypeModel,
    IGetCustomScreensDataModel,
    IGetDocumentsModel,
    IGetEntitiesModel,
    IGetEntityModel,
    IGetEntityPositionsModel,
    IGetProcessExecutionsModel,
    IMacroScriptsGetMonitoringResultForTargetParameters,
    IMonitoringResultsetModel,
    IProcessDefinitionSummaryModel,
    IRatingModel,
    ISecuritiesSubmitCustomScreenDataParameters,
    ISubFundSummaryModel,
    ProcessExecutionSummaryModel} from "proxy/apiProxy";
import { AnyActionOf, produceActionFactories } from "tools/lib/store";
import { IBase64File } from "tools/lib/utility";
import { IGetEntitySummary, IGetRelationshipSummary, IGetSecuritySummary } from "features/Reference/slice";
import { GetAllSummaries } from "features/Document/utils";

export type VisualizationType = "GRID" | "CHART";

export interface IEntityData {
    entity: EntityModel;
    imageFile?: IBase64File;
}

export interface IState {
    ratings?: IRatingModel[];
    entityAllLoading: boolean;
    entityLoading: boolean;
    entities: (EntitySummaryModel | EntityModel)[];
    entityCurrent?: EntityModel;
    entitySaving: boolean;
    positionSaving: boolean;
    entityDeleting: boolean;
    dictionaries: GetAllSummaries &{
        entities: Record<EntitySummaryModel["id"], EntitySummaryModel>;
        processDefinitions: Record<string | number, IProcessDefinitionSummaryModel>;
        customScreens: Record<string | number, CustomScreenSummaryModel>;
        subFunds: Record<number, ISubFundSummaryModel>;
    }
    entityPicture?: IBase64File;

    customScreens?: CustomScreenModel[];
    customScreenDatas?: ICustomScreenDataModel[];
    customScreenDataSubmitting: boolean;
    customScreenDataLoading: boolean;

    compositionDates: Date[];
    compositionDatesLoading: boolean;
    compositionDateLoading: boolean;
    currentCompositionDate?: Date;
    composition?: IEntityPositionModel[];


    compositionVisualizationType: VisualizationType;

    monitoringResults: Record<number, IMonitoringResultsetModel | null>;
    processExecutions?: ProcessExecutionSummaryModel[];
    processExecutionsLoading: boolean;

    documents?: IDocumentSummaryModel[];
    documentsLoading: boolean;
}
export interface IEntityLoadedPayload extends IGetEntityModel {
    entityPicture?: IBase64File;
}
export interface IEntitySavedPayload {
    entity: EntityModel;
}
export const ActionFactories = produceActionFactories({
    entityLoad: (payload: EntityModel["id"] | IEntityTypeModel) => payload,
    entityLoaded: (payload: IEntityLoadedPayload) => payload,
    entitySave: (payload: IEntityData) => payload,
    entitySaved: (payload: IEntitySavedPayload) => payload,
    entityDelete: (payload: EntityModel["id"]) => payload,
    entityDeleted: (payload: EntityModel["id"]) => payload,
    entityLoadAll: (payload: IEntitiesSearchParameters) => payload,
    entityLoadedAll: (payload: IGetEntitiesModel) => payload,
    entityAddEntityInDictionary: (payload: IGetEntitySummary) => payload,
    entityLoadedRelationship: (payload: IGetRelationshipSummary) => payload,
    entityRatingsLoad: (payload: number) => payload,
    entityRatingsLoaded: (payload: IRatingModel[]) => payload,
    entityAddSecurityInDictionary: (payload: IGetSecuritySummary) => payload,

    entityDatesLoad: (payload: number) => payload,
    entityDatesLoaded: (payload: Date[]) => payload,
    entityDateLoad: (payload: Date) => payload,
    entityCompositionLoaded: (payload: IGetEntityPositionsModel) => payload,
    entityDateLoaded: (payload: Date) => payload,
    entityCompositionVisualizationTypeChange: (payload: VisualizationType) => payload,
    entityPositionSave: (payload: IEntityPositionModel) => payload,
    entityPositionSaved: (payload: IEntityPositionModel) => payload,
    entityPositionDelete: (payload: number) => payload,
    entityPositionDeleted: (payload: number) => payload,

    entityCustomScreensLoaded: (payload: CustomScreenModel[]) => payload,
    entityCustomScreenDatasLoad: (payload: number) => payload,
    entityCustomScreenDatasLoaded: (payload: IGetCustomScreensDataModel) => payload,
    entityCustomScreenDataSubmit: (payload: ISecuritiesSubmitCustomScreenDataParameters) => payload,
    entityCustomScreenDataSubmitted: (payload: ICustomScreenDataModel) => payload,

    entityMonitoringResultLoad: (payload: IMacroScriptsGetMonitoringResultForTargetParameters) => payload,
    entityMonitoringResultLoaded: (payload: IMonitoringResultsetModel) => payload,
    entityProcessExecutionsLoad: (payload: number) => payload,
    entityProcessExecutionsLoaded: (payload: IGetProcessExecutionsModel) => payload,

    entityDocumentsLoad: (payload: number) => payload,
    entityDocumentsLoaded: (payload: IGetDocumentsModel) => payload,
});

export function reducer(
    state: IState = {
        entityLoading: false,
        entityAllLoading: false,
        entitySaving: false,
        positionSaving: false,
        entityDeleting: false,
        entities: [],
        dictionaries: {
            entities: {},
            processDefinitions: {},
            relationships: {},
            customScreens: {},
            securities: {},
            subFunds: {},
            transactions: {},
            cashMovements: {},
            portfolios: {},
        },
        compositionDateLoading: false,
        compositionDates: [],
        compositionDatesLoading: false,
        customScreenDataLoading: false,
        customScreenDataSubmitting: false,
        monitoringResults: {},
        compositionVisualizationType: "GRID",
        processExecutionsLoading: false,
        documentsLoading: false,
    },
    action: AnyActionOf<typeof ActionFactories>
): IState {
    return produce(state, draft => {
        switch (action.type) {
            case "entityProcessExecutionsLoad":
                draft.processExecutionsLoading = true;
                break;
            case "entityProcessExecutionsLoaded":
                draft.processExecutionsLoading = false;
                draft.processExecutions = action.payload.processExecutions;
                draft.dictionaries.entities = { ...draft.dictionaries.entities, ...action.payload.entities };
                draft.dictionaries.relationships = { ...draft.dictionaries.relationships, ...action.payload.relationships };
                break;

            case "entityDocumentsLoad":
                draft.documentsLoading = true;
                break;
            case "entityDocumentsLoaded":
                draft.documentsLoading = false;
                draft.documents = action.payload.documents;
                draft.dictionaries.entities = { ...draft.dictionaries.entities, ...action.payload.entities };
                draft.dictionaries.relationships = { ...draft.dictionaries.relationships, ...action.payload.relationships };
                draft.dictionaries.securities = { ...draft.dictionaries.securities, ...action.payload.securities };
                draft.dictionaries.transactions = { ...draft.dictionaries.transactions, ...action.payload.transactions };
                draft.dictionaries.cashMovements = { ...draft.dictionaries.cashMovements, ...action.payload.cashMovements };
                draft.dictionaries.portfolios = { ...draft.dictionaries.portfolios, ...action.payload.portfolios };
                break;

            case "entityPositionSave":
                draft.positionSaving = true;
                break;
            case "entityPositionSaved":
                draft.positionSaving = false;
                if (!draft.composition)
                    draft.composition = [];
                const existingPositionIndex = draft.composition.findIndex(i => i.id === action.payload.id);
                if (existingPositionIndex >= 0) {
                    draft.composition.splice(existingPositionIndex, 1, action.payload);
                }
                else {
                    draft.composition.unshift(action.payload);
                }
                break;
            case "entityPositionDelete":
                draft.positionSaving = true;
                if (draft.composition) {
                    let positionToRemove = draft.composition.findIndex(i => i.id === action.payload);
                    if (positionToRemove >= 0) {
                        draft.composition.splice(positionToRemove, 1);
                    }
                }
                break;
            case "entityPositionDeleted":
                draft.positionSaving = false;
                break;
            case "entityDatesLoad":
                draft.composition = undefined;
                draft.compositionDatesLoading = true;
                draft.processExecutions = undefined;
                break;
            case "entityDatesLoaded":
                draft.compositionDatesLoading = false;
                draft.compositionDates = action.payload.sort((a, b) => (a ? a.getTime() : 0) - (b ? b.getTime() : 0));
                break;
            case "entityDateLoaded":
                draft.currentCompositionDate = action.payload;
                draft.compositionDateLoading = false;
                break;
            case "entityDateLoad":
                draft.compositionDateLoading = true;
                break;
            case "entityCompositionLoaded":
                draft.composition = action.payload.positions;
                draft.dictionaries.securities = { ...draft.dictionaries.securities, ...action.payload.securities }
                draft.dictionaries.subFunds = { ...draft.dictionaries.subFunds, ...action.payload.subFunds }
                draft.dictionaries.entities = { ...draft.dictionaries.entities, ...action.payload.entities }
                break;
            case "entityAddSecurityInDictionary":
                draft.dictionaries.subFunds = { ...draft.dictionaries.subFunds, ...action.payload.subFunds };
                draft.dictionaries.entities = { ...draft.dictionaries.entities, ...action.payload.entities };
                draft.dictionaries.securities[action.payload.security.id] = action.payload.security;
                break;

            case "entityMonitoringResultLoad":
                draft.monitoringResults[action.payload.id] = null;
                break;
            case "entityMonitoringResultLoaded":
                draft.monitoringResults[action.payload.monitoringMacroId] = action.payload;
                break;


            case "entityCustomScreensLoaded":
                draft.customScreens = action.payload;
                break;
            case "entityCustomScreenDatasLoad":
                draft.customScreenDataLoading = true;
                break;
            case "entityCustomScreenDatasLoaded":
                draft.customScreenDataLoading = false;
                draft.dictionaries.entities = { ...draft.dictionaries.entities, ...action.payload.entities };
                draft.dictionaries.customScreens = { ...draft.dictionaries.customScreens, ...action.payload.customScreens };
                draft.customScreenDatas = action.payload.customScreenDatas;
                break;
            case "entityCustomScreenDataSubmit":
                draft.customScreenDataSubmitting = true;
                break;
            case "entityCustomScreenDataSubmitted":
                if (!draft.customScreenDatas) {
                    draft.customScreenDatas = [];
                }
                const customScreenData = draft.customScreenDatas.find(i => i.customScreenId === action.payload.customScreenId);
                if (!!customScreenData) {
                    Object.assign(customScreenData, action.payload);
                }
                else {
                    draft.customScreenDatas.push(action.payload);
                }
                draft.customScreenDataSubmitting = false;
                break;
            case "entityLoadedRelationship":
                draft.dictionaries.relationships[action.payload.relationship.id] = action.payload.relationship;
                draft.dictionaries.entities = { ...draft.dictionaries.entities, ...action.payload.entities };
                draft.dictionaries.relationships = { ...draft.dictionaries.relationships, ...action.payload.relationships };
                break;
            case "entityAddEntityInDictionary":
                draft.dictionaries.entities[action.payload.entity.id] = action.payload.entity;
                draft.dictionaries.entities = { ...draft.dictionaries.entities, ...action.payload.entities };
                break;
            case "entityRatingsLoaded":
                draft.ratings = action.payload;
                break;
            case "entityRatingsLoad":
                draft.ratings = undefined;
                break;
            case "entityLoadAll":
                draft.entityAllLoading = true;
                break;
            case "entityLoadedAll":
                {
                    draft.entityAllLoading = false;
                    const { all, ...dictionaries } = action.payload;
                    draft.entities = all;
                    draft.dictionaries = { ...draft.dictionaries, ...dictionaries };
                }
                break;
            case "entityLoad":
                draft.entityLoading = true;
                draft.entityCurrent = undefined;
                draft.customScreens = undefined;
                draft.customScreenDatas = undefined;
                draft.entityPicture = undefined;
                draft.monitoringResults = {};
                draft.compositionDates = [];
                draft.composition = [];
                draft.documents = undefined;
                draft.entities = [];
                draft.processExecutions = undefined;
                break;
            case "entityLoaded":
                {
                    draft.entityLoading = false;
                    const { entity, entityPicture, ...dictionaries } = action.payload;
                    draft.entityCurrent = entity;
                    draft.entityPicture = entityPicture;
                    draft.dictionaries.entities = { ...draft.dictionaries.entities, ...dictionaries.entities };
                }
                break;
            case "entitySave":
                draft.entitySaving = true;
                break;
            case "entitySaved":
                draft.entitySaving = false;
                const saved = action.payload;
                draft.entityCurrent = saved.entity;
                const existing = draft.entities.find(i => i.id === saved.entity.id);
                if (existing) {
                    Object.assign(existing, saved);
                }
                else {
                    draft.entities.push(saved.entity);
                }
                break;
            case "entityDelete":
                draft.entityDeleting = true;
                break;
            case "entityDeleted":
                draft.entityDeleting = false;
                const deletedId = action.payload;
                if (draft.entityCurrent?.id === deletedId) {
                    delete draft.entityCurrent;
                }
                const idx = draft.entities.findIndex(i => i.id === deletedId);
                if (idx >= 0) {
                    draft.entities.splice(idx, 1);
                }
                break;
            case "entityCompositionVisualizationTypeChange":
                draft.compositionVisualizationType = action.payload;
                break;
        }
    });
}
