import DetailPanel, { ISubMenuTabs } from "tools/components/DetailPanel";
import DetailPanelLoading from "tools/components/DetailPanelLoading";
import { IAccountTypeModel, IBankAccountModel, IFeatureModel, IHistoricalValueSetModel } from "proxy/apiProxy";
import { Formik, FormikHelpers, FormikProps } from 'formik';
import { getEnumLabels, isNumber, useNumber } from "tools/lib/utility";
import { useGrants, useReduxActions, useReduxSelections } from "tools/lib/reduxStoreAccess";
import WriteAccess from "tools/fieldComponents/WriteAccess";
import BankAccountData from "./BankAccountData";
import CustomViewDatas from "features/Relationship/CustomViewDatas";
import { useScreenParams } from "tools/routing/screenRouteHooks"
import { useEffect } from "react";
import { DocumentsGrid } from "features/Document/DocumentsScreen/DocumentsGrid";
import React from "react";
import HistoricalValues, { IDataSeries } from "components/global/HistoricalValues";
import PlaylistAddIcon from "@material-ui/icons/PlaylistAdd";
import { AddHistoricalValue } from "features/Indexes/AddHistoricalValue";

export const bankAccountScreen = {
    component: BankAccount,
    label: "Bank account",
    route: "/bankaccounts/:id/:tab?" as const,
    tabs: {
        detail: "detail",
        historicalValues: "historicalValues",
        documents: "documents",
        customViews: "customScreens"
    },
}

const accountTypes = getEnumLabels(IAccountTypeModel);

function BankAccount() {
    const {
        bankAccountCurrent,
        bankAccountLoading,
        dictionaries,
        customScreenDataLoading,
        customScreenDatas,
        customScreens,
        customScreenDataSubmitting,
        documents,
        documentsLoading,
        historicalValues,
        historicalValuesVisualizationType,
        historicalLoading,
        historicalValueSaving,
    } = useReduxSelections("bankAccount");
    const { parameters: { securityExtensionFields = [] } } = useReduxSelections("parameters");
    const {
        bankAccountDelete,
        bankAccountSave,
        bankAccountAddEntityInDictionary,
        bankAccountAddPortfolioInDictionary,
        securityCustomScreenDataSubmit,
        bankAccountLoad,
        bankAccountDocumentsLoad,
        securityHistoricalValuesLoad,
        securitySaveHistoricalValueSave,
        securityHistoricalValuesVisualizationTypeChanged,
    } = useReduxActions("bankAccount");

    const {
        referenceCountries = {},
        referenceCurrencies = {},
    } = useReduxSelections("reference");

    const { id, tab: tabValue = "detail" } = useScreenParams<typeof bankAccountScreen>();
    const idNum = useNumber(id);

    useEffect(() => {
        if (isNumber(idNum)) bankAccountLoad(idNum);
    }, [bankAccountLoad, idNum]);

    useEffect(() => {
        if (idNum && tabValue === "historicalValues" && !historicalValues) {
            securityHistoricalValuesLoad(idNum)
        }
    }, [idNum, securityHistoricalValuesLoad, historicalValues, tabValue]);

    useEffect(() => {
        if (idNum && tabValue === "documents" && !documentsLoading && !documents)
            bankAccountDocumentsLoad(idNum)
    }, [idNum, bankAccountDocumentsLoad, documents, tabValue]);

    const [addingNewHistValue, setAddingNewHistValue] = React.useState(false);

    const isGranted = useGrants();
    if (!bankAccountCurrent) {
        return <DetailPanelLoading hasSubTitle={false} />;
    }

    const handleDelete = () => {
        if (!!bankAccountCurrent?.id) {
            bankAccountDelete(bankAccountCurrent.id);
        }
    }

    const handleDataSubmit = (customScreenId: number, values: any) =>
        securityCustomScreenDataSubmit({ id: bankAccountCurrent?.id, customScreenId, values });

    const canEditCustomData = isGranted(IFeatureModel.ManagedPortfolioWrite);

    const title = !!bankAccountCurrent?.id ? bankAccountCurrent.name : 'New bank account';
    const handleSubmit = (values: IBankAccountModel, { setSubmitting }: FormikHelpers<IBankAccountModel>) => {
        bankAccountSave(values);
        setSubmitting(false);
    }
    
    const historicalValueProperties = getHistoricalValueTypes();
    const graphHistoricalValueProperties = getGraphHistoricalValueTypes();

    const handleValueChanged = (date: Date, type: string, value: number | undefined) => {
        securitySaveHistoricalValueSave({
            historicalValue: {
                date,
                type,
                value: value
            },
            securityId: bankAccountCurrent.id
        })
    }

    const extraActionButtons = (() => {
        const ret = [];
        if (tabValue === "historicalValues") {
            ret.push({
                label: "Add new Historical Value",
                onClick: () => setAddingNewHistValue(true),
                icon: PlaylistAddIcon
            });
        }
        return ret;
    })();
    const handleCloseNewHistValue = (hv: IHistoricalValueSetModel) => {
        setAddingNewHistValue(false);
        if (bankAccountCurrent?.id) {
            securitySaveHistoricalValueSave({
                historicalValue: hv,
                securityId: bankAccountCurrent.id
            });
        }
    };
    const handleCloseNewHistValueCancel = () => setAddingNewHistValue(false);

    return <Formik onSubmit={handleSubmit} initialValues={bankAccountCurrent} enableReinitialize={true} validateOnMount={true} >{renderForm}</Formik>;
    function renderForm({ dirty, isValid, submitForm, values, validateForm, setFieldValue }: FormikProps<IBankAccountModel>) {

        const tabs: ISubMenuTabs<typeof bankAccountScreen>[] = [
            {
                label: "Data",
                value: "detail"
            },
            {
                label: "Historical values",
                value: "historicalValues"
            }
        ];

        if (values?.id) {
            tabs.push({
                label: "Documents",
                value: "documents"
            });

            if (!!customScreens) {
                tabs.push({
                    label: "Custom Data",
                    value: "customViews"
                });
            }
        }

        return <DetailPanel
            isQuerying={bankAccountLoading || customScreenDataLoading || customScreenDataSubmitting
                || documentsLoading || historicalLoading || historicalValueSaving}
            title={title}
            canSave={dirty && isValid}
            onSaveClick={submitForm}
            canDelete={!!values.id}
            onDeleteClick={handleDelete}
            saveAllowed={IFeatureModel.ManagedPortfolioWrite}
            deleteAllowed={IFeatureModel.ManagedPortfolioWrite}
            badge={!values.id ? "new" : undefined}
            tabs={tabs}
            actions={extraActionButtons}
            tabValue={tabValue}>

            <AddHistoricalValue
                isOpened={addingNewHistValue}
                onAdd={handleCloseNewHistValue}
                onCancel={handleCloseNewHistValueCancel}
                typeDescriptions={historicalValueProperties} />

            {(tabValue === "detail") &&
                <WriteAccess value={IFeatureModel.ManagedPortfolioWrite}>
                    <BankAccountData
                        referenceCountries={referenceCountries}
                        referenceCurrencies={referenceCurrencies}
                        securityExtensionFields={securityExtensionFields}
                        accountTypes={accountTypes}
                        dictionaries={dictionaries}
                        bankAccountAddEntityInDictionary={bankAccountAddEntityInDictionary}
                        bankAccountAddPortfolioInDictionary={bankAccountAddPortfolioInDictionary}
                    />
                </WriteAccess>
            }
            {(tabValue === "historicalValues") && <HistoricalValues
                historicalValues={historicalValues}
                properties={historicalValueProperties}
                graphProperties={graphHistoricalValueProperties}
                onVisualizationTypeChanged={securityHistoricalValuesVisualizationTypeChanged}
                visualizationType={historicalValuesVisualizationType}
                editable={isGranted(IFeatureModel.UniverseWrite)}
                onValueChanged={handleValueChanged}
            />}
            {(tabValue === "documents") && <DocumentsGrid
                documents={documents ?? []}
                dictionaries={dictionaries} />}
            {(tabValue === "customViews" && !!customScreenDatas && !!customScreens?.length) &&
                <CustomViewDatas
                    canEdit={canEditCustomData}
                    dictionaries={dictionaries}
                    customScreenLoading={customScreenDataLoading}
                    dataSubmitting={customScreenDataSubmitting}
                    customScreens={customScreens}
                    onDataSubmit={handleDataSubmit}
                    customScreenDatas={customScreenDatas}
                />}
        </DetailPanel>;
    }
}

function getGraphHistoricalValueTypes(): IDataSeries {
    return {
        MKT: "Price",
        RATE: "Rate",
    };
}

function getHistoricalValueTypes(): IDataSeries {
    return {
        MKT: "Price",
        RATE: "Rate",
    };
}
