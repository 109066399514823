import produce from "immer";
import { SecurityModel, ISecurityTypeModel, IHistoricalValuesModel, IGetSecuritiesModel, IGetSecurityModel, SecuritySummaryModel, IIndexSummaryModel, ISecuritiesSearchParameters, ISubFundSummaryModel, IProxyPositionModel, IGetSecurityProxyModel, IRatingModel, IHistoricalValueSetModel, CustomScreenModel, ICustomScreenDataModel, CustomScreenSummaryModel, ISecuritiesSubmitCustomScreenDataParameters, IGetCustomScreensDataModel, IMonitoringResultsetModel, IMacroScriptsGetMonitoringResultForTargetParameters, ProcessExecutionSummaryModel, IGetProcessExecutionsModel, IDocumentSummaryModel, IGetDocumentsModel } from "proxy/apiProxy";
import { produceActionFactories, AnyActionOf } from "tools/lib/store";
import { IGetSubFundSummary, IGetRelationshipSummary, IGetSecuritySummary, IGetEntitySummary } from "../Reference/slice";
import { GetAllSummaries } from "features/Document/utils";

export type VisualizationType = "GRID" | "CHART";
export interface ISecurityData {
    security: SecurityModel;
    proxy?: IProxyPositionModel[];
}
export interface IState {
    securitySaveHistoricalValueSaving: boolean;
    allLoading: boolean;
    loading: boolean;
    current?: SecurityModel;
    // currentProxy?:
    historicalValues?: IHistoricalValuesModel[];
    ratings?: IRatingModel[];
    historicalLoading: boolean;
    saving: boolean;
    deleting: boolean;
    historicalValuesVisualizationType: VisualizationType;
    pricingDates: Date[];
    pricingDatesLoading: boolean;
    pricingDate?: Date;
    proxy?: IProxyPositionModel[];
    all: (SecuritySummaryModel | SecurityModel)[];

    dictionaries: GetAllSummaries & {
        subFunds: Record<string | number, ISubFundSummaryModel>;
        indexes: Record<string | number, IIndexSummaryModel>;
        customScreens: Record<string | number, CustomScreenSummaryModel>;
    }

    customScreens?: CustomScreenModel[];
    customScreenDatas?: ICustomScreenDataModel[];
    customScreenDataSubmitting: boolean;
    customScreenDataLoading: boolean;

    monitoringResults: Record<number, IMonitoringResultsetModel | null>;
    processExecutions?: ProcessExecutionSummaryModel[];
    processExecutionsLoading: boolean;

    documents?: IDocumentSummaryModel[];
    documentsLoading: boolean;
}
export interface ISaveHistoricalValuePayload {
    securityId: number;
    historicalValue: IHistoricalValueSetModel;
}

export interface ISecuritySavedPayload {
    security: SecurityModel;
}
export const ActionFactories = produceActionFactories({
    securityLoad: (payload: number | ISecurityTypeModel) => payload,
    // securityReportsLoaded: (payload: IReportTemplateSummaryModel[]) => payload,
    // securityReportGenerate: (payload: ISecurityGenerateReportPayload) => payload,
    // securityReportGenerated: () => undefined,
    securitySearch: (payload: ISecuritiesSearchParameters) => payload,
    securityLoaded: (payload: IGetSecurityModel) => payload,
    securitySave: (payload: ISecurityData) => payload,
    securitySaved: (payload: ISecuritySavedPayload) => payload,
    securityDelete: (payload: number) => payload,
    securityDeleted: (payload: number) => payload,
    securityLoadAll: () => undefined,
    securityLoadedAll: (payload: IGetSecuritiesModel) => payload,
    securityHistoricalValuesLoad: (payload: number) => payload,
    securityHistoricalValuesLoaded: (payload: IHistoricalValuesModel[]) => payload,
    securityRatingsLoad: (payload: number) => payload,
    securityRatingsLoaded: (payload: IRatingModel[]) => payload,
    securityHistoricalValuesVisualizationTypeChanged: (payload: VisualizationType) => payload,
    securityPricingDatesLoad: (payload: number) => payload,
    securityPricingDatesLoaded: (payload: Date[]) => payload,
    securityPricingDateLoad: (payload: Date) => payload,
    securityLoadedRelationship: (payload: IGetRelationshipSummary) => payload,
    securityAddSecurityInDictionary: (payload: IGetSecuritySummary) => payload,
    securityAddIndexInDictionary: (payload: IIndexSummaryModel) => payload,
    securityAddSubFundInDictionary: (payload: IGetSubFundSummary) => payload,
    securityAddEntityInDictionary: (payload: IGetEntitySummary) => payload,
    securitySaveHistoricalValueSave: (payload: ISaveHistoricalValuePayload) => payload,
    securitySaveHistoricalValueSaved: (payload: ISaveHistoricalValuePayload) => payload,


    securityCustomScreensLoaded: (payload: CustomScreenModel[]) => payload,
    securityCustomScreenDatasLoad: (payload: number) => payload,
    securityCustomScreenDatasLoaded: (payload: IGetCustomScreensDataModel) => payload,
    securityCustomScreenDataSubmit: (payload: ISecuritiesSubmitCustomScreenDataParameters) => payload,
    securityCustomScreenDataSubmitted: (payload: ICustomScreenDataModel) => payload,

    securityProxyLoaded: (payload: IGetSecurityProxyModel) => payload,

    securityMonitoringResultLoad: (payload: IMacroScriptsGetMonitoringResultForTargetParameters) => payload,
    securityMonitoringResultLoaded: (payload: IMonitoringResultsetModel) => payload,

    securityProcessExecutionsLoad: (payload: number) => payload,
    securityProcessExecutionsLoaded: (payload: IGetProcessExecutionsModel) => payload,

    securityDocumentsLoad: (payload: number) => payload,
    securityDocumentsLoaded: (payload: IGetDocumentsModel) => payload,
});

export function reducer(
    state: IState = {
        securitySaveHistoricalValueSaving: false,
        loading: false,
        allLoading: false,
        saving: false,
        deleting: false,
        historicalLoading: false,
        historicalValuesVisualizationType: "GRID",
        pricingDates: [],
        pricingDatesLoading: false,
        dictionaries: {
            entities: {},
            relationships: {},
            subFunds: {},
            securities: {},
            indexes: {},
            customScreens: {},
            portfolios: {},
            cashMovements: {},
            transactions: {},
        },
        all: [],
        customScreenDataLoading: false,
        customScreenDataSubmitting: false,
        monitoringResults: {},
        processExecutionsLoading: false,
        documentsLoading: false,
    },
    action: AnyActionOf<typeof ActionFactories>
): IState {
    return produce(state, draft => {
        switch (action.type) {
            case "securityProcessExecutionsLoad":
                draft.processExecutionsLoading = true;
                break;
            case "securityProcessExecutionsLoaded":
                draft.processExecutionsLoading = false;
                draft.processExecutions = action.payload.processExecutions;
                draft.dictionaries.entities = { ...draft.dictionaries.entities, ...action.payload.entities };
                draft.dictionaries.relationships = { ...draft.dictionaries.relationships, ...action.payload.relationships };
                break;
            case "securityDocumentsLoad":
                draft.documentsLoading = true;
                break;
            case "securityDocumentsLoaded":
                draft.documentsLoading = false;
                draft.documents = action.payload.documents;
                draft.dictionaries.entities = { ...draft.dictionaries.entities, ...action.payload.entities };
                draft.dictionaries.securities = { ...draft.dictionaries.securities, ...action.payload.securities };
                draft.dictionaries.relationships = { ...draft.dictionaries.relationships, ...action.payload.relationships };
                draft.dictionaries.cashMovements = { ...draft.dictionaries.cashMovements, ...action.payload.cashMovements };
                draft.dictionaries.transactions = { ...draft.dictionaries.transactions, ...action.payload.transactions };
                draft.dictionaries.portfolios = { ...draft.dictionaries.portfolios, ...action.payload.portfolios };
                break;
            case "securityMonitoringResultLoad":
                draft.monitoringResults[action.payload.id] = null;
                break;
            case "securityMonitoringResultLoaded":
                draft.monitoringResults[action.payload.monitoringMacroId] = action.payload;
                break;

            case "securityCustomScreensLoaded":
                draft.customScreens = action.payload;
                break;
            case "securityCustomScreenDatasLoad":
                draft.customScreenDataLoading = true;
                break;
            case "securityCustomScreenDatasLoaded":
                draft.customScreenDataLoading = false;
                draft.dictionaries.entities = { ...draft.dictionaries.entities, ...action.payload.entities };
                draft.dictionaries.customScreens = { ...draft.dictionaries.customScreens, ...action.payload.customScreens };
                draft.customScreenDatas = action.payload.customScreenDatas;
                break;
            case "securityCustomScreenDataSubmit":
                draft.customScreenDataSubmitting = true;
                break;
            case "securityCustomScreenDataSubmitted":
                if (!draft.customScreenDatas) {
                    draft.customScreenDatas = [];
                }
                const customScreenData = draft.customScreenDatas.find(i => i.customScreenId === action.payload.customScreenId);
                if (!!customScreenData) {
                    Object.assign(customScreenData, action.payload);
                }
                else {
                    draft.customScreenDatas.push(action.payload);
                }
                draft.customScreenDataSubmitting = false;
                break;

            case "securitySaveHistoricalValueSave":
                draft.securitySaveHistoricalValueSaving = true;
                break;
            case "securitySaveHistoricalValueSaved":
                draft.securitySaveHistoricalValueSaving = false;
                if (draft.historicalValues) {
                    const hv = draft.historicalValues.find(i => i.date === action.payload.historicalValue.date);
                    if (!!hv) {
                        if (!action.payload.historicalValue.value) {
                            delete hv.values[action.payload.historicalValue.type];
                        }
                        else {
                            hv.values[action.payload.historicalValue.type] = action.payload.historicalValue.value;
                        }
                    } else {
                        if (action.payload.historicalValue.value) {
                            draft.historicalValues.push({
                                date: action.payload.historicalValue.date,
                                values: {
                                    [action.payload.historicalValue.type]: action.payload.historicalValue.value
                                }
                            });
                        }
                    }
                }
                break;
            case "securityProxyLoaded":
                draft.proxy = action.payload.proxyPositions;
                draft.dictionaries.securities = { ...draft.dictionaries.securities, ...action.payload.securities };
                draft.dictionaries.indexes = { ...draft.dictionaries.indexes, ...action.payload.indexes };
                break;
            case "securityLoadedRelationship":
                draft.dictionaries.relationships[action.payload.relationship.id] = action.payload.relationship;
                draft.dictionaries.entities = { ...draft.dictionaries.entities, ...action.payload.entities };
                draft.dictionaries.relationships = { ...draft.dictionaries.relationships, ...action.payload.relationships };
                break;
            case "securityPricingDatesLoad":
                draft.pricingDatesLoading = true;
                break;
            case "securityPricingDatesLoaded":
                draft.pricingDatesLoading = false;
                draft.pricingDates = action.payload.sort((a, b) => (a ? a.getTime() : 0) - (b ? b.getTime() : 0));
                if (draft.pricingDates.length > 0) {
                    draft.pricingDate = draft.pricingDates[draft.pricingDates.length - 1];
                }
                else {
                    draft.pricingDate = undefined;
                }
                break;
            case "securityPricingDateLoad":
                draft.pricingDate = action.payload;
                break;
            case "securitySearch":
                draft.allLoading = true;
                break;
            case "securityLoadAll":
                draft.allLoading = true;
                break;
            case "securityLoadedAll":
                draft.allLoading = false;
                draft.dictionaries = {
                    relationships: {},
                    subFunds: action.payload.subFunds,
                    entities: action.payload.entities,
                    securities: {},
                    indexes: {},
                    customScreens: {},
                    portfolios: {},
                    cashMovements: {},
                    transactions: {},
                }
                draft.all = action.payload.securities;
                break;
            case "securityLoad":
                draft.customScreens = undefined;
                draft.customScreenDatas = undefined;
                draft.loading = true;
                draft.current = undefined;
                draft.proxy = undefined;
                draft.ratings = undefined;
                draft.monitoringResults = {};
                draft.documents = undefined;
                draft.historicalValues = undefined;
                draft.processExecutions = undefined;
                break;
            case "securityLoaded":
                draft.loading = false;
                draft.current = action.payload.security;
                draft.dictionaries.entities = { ...draft.dictionaries.entities, ...action.payload.entities };
                draft.dictionaries.relationships = { ...draft.dictionaries.relationships, ...action.payload.relationships };
                draft.dictionaries.subFunds = { ...draft.dictionaries.subFunds, ...action.payload.subFunds };
                break;
            case "securitySave":
                draft.saving = true;
                break;
            case "securitySaved":
                draft.saving = false;
                const saved = action.payload;
                draft.current = saved.security;
                const existing = draft.all.find(i => i.id === saved.security.id);
                if (existing) {
                    Object.assign(existing, saved);
                }
                else {
                    draft.all.push(saved.security);
                }
                break;
            case "securityHistoricalValuesVisualizationTypeChanged":
                draft.historicalValuesVisualizationType = action.payload;
                break;
            case "securityDelete":
                draft.deleting = true;
                break;
            case "securityDeleted":
                draft.deleting = false;
                const deletedId = action.payload;
                if (draft.current && draft.current.id === deletedId) {
                    delete draft.current;
                }
                const idx = draft.all.findIndex(i => i.id === deletedId);
                if (idx >= 0) {
                    draft.all.splice(idx, 1);
                }
                break;
            case "securityHistoricalValuesLoaded":
                draft.historicalValues = action.payload;
                draft.historicalLoading = false;
                break;
            case "securityHistoricalValuesLoad":
                draft.historicalValues = undefined;
                draft.historicalLoading = true;
                break;
            case "securityRatingsLoaded":
                draft.ratings = action.payload;
                break;
            case "securityRatingsLoad":
                draft.ratings = undefined;
                break;
            case "securityAddSecurityInDictionary":
                draft.dictionaries.subFunds = { ...draft.dictionaries.subFunds, ...action.payload.subFunds };
                draft.dictionaries.entities = { ...draft.dictionaries.entities, ...action.payload.entities };
                draft.dictionaries.securities[action.payload.security.id] = action.payload.security;
                break;
            case "securityAddSubFundInDictionary":
                draft.dictionaries.entities = { ...draft.dictionaries.entities, ...action.payload.entities };
                draft.dictionaries.subFunds[action.payload.subFund.id] = action.payload.subFund;
                break;
            case "securityAddEntityInDictionary":
                draft.dictionaries.entities = { ...draft.dictionaries.entities, ...action.payload.entities };
                draft.dictionaries.entities[action.payload.entity.id] = action.payload.entity;
                break;
            case "securityAddIndexInDictionary":
                draft.dictionaries.indexes[action.payload.id] = action.payload;
                break;
        }
    });
}
