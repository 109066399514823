import { ICompletionDataModel, IEntityMetadataModel, IMacroModel, IMacroTypeModel, ITextModelWithPosition } from "proxy/apiProxy";
import { toDictionary } from "tools/lib/utility";
import MacroCodeEditor, { IExceptionCodeEditor } from "./MacroCodeEditor";
import { Box, TextField, LinearProgress, withStyles, createStyles } from "@material-ui/core";
import ReactJson from 'react-json-view'
import { ReadOnlyContext } from "tools/fieldComponents/ReadOnlyContext";
import { useCallback, useContext, useMemo, useState } from "react";
import { IMacroDiagnostic, ITypedMacro } from "../slice";
import { INotControllableEditorProps } from "./INotControllableEditorProps";
import { SplitPane } from "tools/splitPane/SplitPane";
const ContainerBox = withStyles(theme =>
    createStyles({
        root: {
            // padding: theme.spacing(2),
            display: "flex",
            flexDirection: "column",
            width: "100%",
            height: "100%",
            overflowY: "auto"
        },
    })
)(Box);
export interface IFormMacroEditorProps extends INotControllableEditorProps<IMacroModel> {
    metadata: Record<string | number, IEntityMetadataModel>;
    error?: IExceptionCodeEditor;
    executing: boolean;
    diagnostics?: IMacroDiagnostic;
    type: IMacroTypeModel;
    onCodeChanged: (macro: ITypedMacro) => void;
    onRequestAutoComplete?: (position: ITextModelWithPosition) => Promise<ICompletionDataModel[]>
}
export default function FormMacroEditor({ type, onCodeChanged, metadata, error, executing, onRequestAutoComplete, diagnostics, refToGetValue }: IFormMacroEditorProps) {
    const readOnly = useContext(ReadOnlyContext);
    const handleCodeChanged = useCallback((macroModel: IMacroModel) => onCodeChanged({ ...macroModel, type }), [onCodeChanged, type]);
    return <SplitPane defaultSize={1250}>
        <Box style={{ display: "flex", height: "100%", flexDirection: "column" }}>
            <LinearProgress hidden={!executing} variant="query" />
            <MacroCodeEditor
                exception={error}
                onCodeChanged={handleCodeChanged}
                refToGetValue={refToGetValue}
                readOnly={readOnly}
                codeErrors={diagnostics?.errors}
                onRequestAutoComplete={onRequestAutoComplete} />
        </Box>
        <MetadataTree metadata={metadata} />
    </SplitPane>
}
interface IMetadataTreeProps {
    metadata: Record<string | number, IEntityMetadataModel>;
}
function MetadataTree({ metadata }: IMetadataTreeProps) {
    const [filter, setFilter] = useState("");
    const handleChangeFilter = useCallback((event: React.ChangeEvent<HTMLInputElement>) => setFilter(event.target.value), []);
    const filteredMetadata = useMemo(() => {
        if (!filter || filter === "") {
            return metadata;
        }
        return toDictionary(Object.values(metadata).filter(i => (i.name ?? "").toLowerCase().includes(filter.toLowerCase())), i => i.name ?? "");
    }, [metadata, filter]);
    return <ContainerBox>
        <TextField value={filter} onChange={handleChangeFilter} label="Filter" />
        <ReactJson
            style={{ width: "100%", overflowY: "auto" }}
            name="Referential"
            collapsed={1}
            displayDataTypes={false}
            displayObjectSize={false}
            src={filteredMetadata} />
    </ContainerBox>
}
