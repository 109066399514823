import produce from "immer";
import {
    IManagedShareClassModel, IHistoricalValuesModel, IGetShareClassesModel, IGetShareClassModel, IShareClassSummaryModel,
    ISecuritiesSearchParameters, ISubFundSummaryModel, CustomScreenSummaryModel, CustomScreenModel, ICustomScreenDataModel, IManagedShareClassesSubmitCustomScreenDataParameters, IGetCustomScreensDataModel, IMonitoringResultsetModel, IMacroScriptsGetMonitoringResultForTargetParameters, IIndexSummaryModel, ProcessExecutionSummaryModel, IGetProcessExecutionsModel,
    IGetDocumentsModel,
    IDocumentSummaryModel
} from "proxy/apiProxy";
import { produceActionFactories, AnyActionOf } from "tools/lib/store";
import { IGetPortfolioSummary } from "../Reference/slice";
import { GetAllSummaries } from "features/Document/utils";

export type VisualizationType = "GRID" | "CHART";

export interface IState {
    allLoading: boolean;
    loading: boolean;
    current?: IManagedShareClassModel;
    historicalValues?: IHistoricalValuesModel[];
    historicalLoading: boolean;
    saving: boolean;
    deleting: boolean;
    historicalValuesVisualizationType: VisualizationType;
    pricingDates: Date[];
    pricingDatesLoading: boolean;
    pricingDate?: Date;

    all: (IShareClassSummaryModel | IManagedShareClassModel)[];

    dictionaries: GetAllSummaries & {
        subFunds: Record<string | number, ISubFundSummaryModel>;
        indexes: Record<number, IIndexSummaryModel>; // new
        customScreens: Record<string | number, CustomScreenSummaryModel>;
    }
    customScreens?: CustomScreenModel[];
    customScreenDatas?: ICustomScreenDataModel[];
    customScreenDataSubmitting: boolean;
    customScreenDataLoading: boolean;

    monitoringResults: Record<number, IMonitoringResultsetModel | null>;
    processExecutions?: ProcessExecutionSummaryModel[];
    processExecutionsLoading: boolean;

    documents?: IDocumentSummaryModel[];
    documentsLoading: boolean;
}

export type ShareClassFileType = "check" | "compliance";

export interface IFileKey {
    type: ShareClassFileType;
    taskCode: string;
}
export interface IGetFilePayload {
    type: ShareClassFileType;
    dueDiligenceExecutionId: number,
    taskCode: string
}

export const ActionFactories = produceActionFactories({
    shareClassLoad: (payload: number) => payload,
    shareClassSearch: (payload: ISecuritiesSearchParameters) => payload,
    shareClassLoaded: (payload: IGetShareClassModel) => payload,
    shareClassSave: (payload: IManagedShareClassModel) => payload,
    shareClassSaved: (payload: IManagedShareClassModel) => payload,
    shareClassDelete: (payload: number) => payload,
    shareClassDeleted: (payload: number) => payload,
    shareClassLoadAll: () => undefined,
    shareClassLoadedAll: (payload: IGetShareClassesModel) => payload,
    shareClassHistoricalValuesLoad: (payload: number) => payload,
    shareClassHistoricalValuesLoaded: (payload: IHistoricalValuesModel[]) => payload,
    shareClassHistoricalValuesVisualizationTypeChanged: (payload: VisualizationType) => payload,
    shareClassAddSubFundInDictionary: (payload: IGetPortfolioSummary) => payload,

    shareClassCustomScreensLoaded: (payload: CustomScreenModel[]) => payload,
    shareClassCustomScreenDatasLoad: (payload: number) => payload,
    shareClassCustomScreenDatasLoaded: (payload: IGetCustomScreensDataModel) => payload,
    shareClassCustomScreenDataSubmit: (payload: IManagedShareClassesSubmitCustomScreenDataParameters) => payload,
    shareClassCustomScreenDataSubmitted: (payload: ICustomScreenDataModel) => payload,

    shareClassMonitoringResultLoad: (payload: IMacroScriptsGetMonitoringResultForTargetParameters) => payload,
    shareClassMonitoringResultLoaded: (payload: IMonitoringResultsetModel) => payload,
    shareClassAddIndexInDictionary: (payload: IIndexSummaryModel) => payload,
    shareClassProcessExecutionsLoad: (payload: number) => payload,
    shareClassProcessExecutionsLoaded: (payload: IGetProcessExecutionsModel) => payload,

    shareClassDocumentsLoad: (payload: number) => payload,
    shareClassDocumentsLoaded: (payload: IGetDocumentsModel) => payload,
});

export function reducer(
    state: IState = {
        loading: false,
        allLoading: false,
        saving: false,
        deleting: false,
        historicalLoading: false,
        historicalValuesVisualizationType: "GRID",
        pricingDates: [],
        pricingDatesLoading: false,
        dictionaries: {
            entities: {},
            subFunds: {},
            customScreens: {},
            indexes: {},
            relationships: {},
            portfolios: {},
            securities: {},
            transactions: {},
            cashMovements: {}
        },
        customScreenDataLoading: false,
        customScreenDataSubmitting: false,
        all: [],
        monitoringResults: {},
        processExecutionsLoading: false,
        documentsLoading: false
    },
    action: AnyActionOf<typeof ActionFactories>
): IState {
    return produce(state, draft => {
        switch (action.type) {
            case "shareClassProcessExecutionsLoad":
                draft.processExecutionsLoading = true;
                break;
            case "shareClassProcessExecutionsLoaded":
                draft.processExecutionsLoading = false;
                draft.processExecutions = action.payload.processExecutions;
                draft.dictionaries.entities = { ...draft.dictionaries.entities, ...action.payload.entities };
                draft.dictionaries.relationships = { ...draft.dictionaries.relationships, ...action.payload.relationships };
                break;

            case "shareClassDocumentsLoad":
                draft.documentsLoading = true;
                break;
            case "shareClassDocumentsLoaded":
                draft.documentsLoading = false;
                draft.documents = action.payload.documents;
                draft.dictionaries.entities = { ...draft.dictionaries.entities, ...action.payload.entities };
                draft.dictionaries.relationships = { ...draft.dictionaries.relationships, ...action.payload.relationships };
                draft.dictionaries.portfolios = { ...draft.dictionaries.portfolios, ...action.payload.portfolios };
                draft.dictionaries.cashMovements = { ...draft.dictionaries.cashMovements, ...action.payload.cashMovements };
                draft.dictionaries.transactions = { ...draft.dictionaries.transactions, ...action.payload.transactions };
                draft.dictionaries.securities = { ...draft.dictionaries.securities, ...action.payload.securities };
                break;

            case "shareClassMonitoringResultLoad":
                draft.monitoringResults[action.payload.id] = null;
                break;
            case "shareClassMonitoringResultLoaded":
                draft.monitoringResults[action.payload.monitoringMacroId] = action.payload;
                break;

            case "shareClassCustomScreensLoaded":
                draft.customScreens = action.payload;
                break;
            case "shareClassCustomScreenDatasLoad":
                draft.customScreenDataLoading = true;
                break;
            case "shareClassCustomScreenDatasLoaded":
                draft.customScreenDataLoading = false;
                draft.dictionaries.entities = { ...draft.dictionaries.entities, ...action.payload.entities };
                draft.dictionaries.customScreens = { ...draft.dictionaries.customScreens, ...action.payload.customScreens };
                draft.customScreenDatas = action.payload.customScreenDatas;
                break;
            case "shareClassCustomScreenDataSubmit":
                draft.customScreenDataSubmitting = true;
                break;
            case "shareClassCustomScreenDataSubmitted":
                if (!draft.customScreenDatas) {
                    draft.customScreenDatas = [];
                }
                const customScreenData = draft.customScreenDatas.find(i => i.customScreenId === action.payload.customScreenId);
                if (!!customScreenData) {
                    Object.assign(customScreenData, action.payload);
                }
                else {
                    draft.customScreenDatas.push(action.payload);
                }
                draft.customScreenDataSubmitting = false;
                break;

            case "shareClassSearch":
                draft.allLoading = true;
                break;
            case "shareClassLoadAll":
                draft.allLoading = true;
                break;
            case "shareClassLoadedAll":
                draft.allLoading = false;
                draft.dictionaries = {
                    subFunds: action.payload.subFunds,
                    entities: action.payload.entities,
                    customScreens: {},
                    indexes: {},
                    relationships: {},
                    portfolios: {},
                    securities: {},
                    transactions: {},
                    cashMovements: {}
                }
                draft.all = action.payload.shareClasses;
                break;
            case "shareClassLoad":
                draft.customScreens = undefined;
                draft.customScreenDatas = undefined;
                draft.loading = true;
                draft.current = undefined;
                draft.monitoringResults = {};
                draft.documents = undefined;
                draft.historicalValues = undefined;
                draft.processExecutions = undefined;
                break;
            case "shareClassLoaded":
                draft.loading = false;
                draft.current = action.payload.shareClass;
                draft.dictionaries.entities = { ...draft.dictionaries.entities, ...action.payload.entities };
                draft.dictionaries.subFunds = { ...draft.dictionaries.subFunds, ...action.payload.subFunds };
                break;
            case "shareClassSave":
                draft.saving = true;
                break;
            case "shareClassSaved":
                draft.saving = false;
                draft.current = action.payload;
                const existing = draft.all.find(i => i.id === action.payload.id);
                if (existing) {
                    Object.assign(existing, action.payload);
                }
                else {
                    draft.all.push(action.payload);
                }
                break;
            case "shareClassHistoricalValuesVisualizationTypeChanged":
                draft.historicalValuesVisualizationType = action.payload;
                break;
            case "shareClassDelete":
                draft.deleting = true;
                break;
            case "shareClassDeleted":
                draft.deleting = false;
                const deletedId = action.payload;
                if (draft.current && draft.current.id === deletedId) {
                    delete draft.current;
                }
                const idx = draft.all.findIndex(i => i.id === deletedId);
                if (idx >= 0) {
                    draft.all.splice(idx, 1);
                }
                break;
            case "shareClassHistoricalValuesLoaded":
                draft.historicalValues = action.payload;
                draft.historicalLoading = false;
                break;
            case "shareClassHistoricalValuesLoad":
                draft.historicalLoading = true;
                break;
            case "shareClassAddSubFundInDictionary":
                draft.dictionaries.entities = { ...draft.dictionaries.entities, ...action.payload.entities };
                if (action.payload.portfolio.type === "SubFundSummaryModel") {
                    draft.dictionaries.subFunds[action.payload.portfolio.id] = action.payload.portfolio;
                }
                break;
            case "shareClassAddIndexInDictionary":
                draft.dictionaries.indexes[action.payload.id] = action.payload;
                break;
        }
    });
}
