import produce from "immer";
import {
    PortfolioSummaryModel, RelationshipSummaryModel, RelationshipModel, IGetRelationshipModel, IGetRelationshipsModel,
    CustomScreenSummaryModel, IGetCustomScreensDataModel,
    IInvestorRelationshipsSubmitCustomScreenDataParameters,
    IRoleRelationshipsSubmitCustomScreenDataParameters,
    ICounterpartyRelationshipsSubmitCustomScreenDataParameters,
    ICustomScreenDataModel, CustomScreenModel, IMonitoringResultsetModel, IMacroScriptsGetMonitoringResultForTargetParameters, ProcessExecutionSummaryModel, IGetProcessExecutionsModel,
    IDocumentSummaryModel,
    IGetDocumentsModel
} from "proxy/apiProxy";
import { produceActionFactories, AnyActionOf } from "tools/lib/store";
import { IGetEntitySummary, IGetRelationshipSummary, IGetSecuritySummary } from "../Reference/slice";
import { GetAllSummaries } from "features/Document/utils";

export type IRelationshipsSubmitCustomScreenDataParameters = (IInvestorRelationshipsSubmitCustomScreenDataParameters
    | IRoleRelationshipsSubmitCustomScreenDataParameters
    | ICounterpartyRelationshipsSubmitCustomScreenDataParameters) & {
        type: RelationshipModel["type"]
    };

export interface IRelationshipLoad {
    id: number;
    type: RelationshipModel["type"];
}

export interface IRelationshipLoadProcesses {
    ids: number[];
    type: RelationshipModel["type"];
}

export const ActionFactories = produceActionFactories({
    relationshipDeleteCurrent: (payload: IRelationshipLoad) => payload,
    relationshipDeleted: (payload: number) => payload,
    relationshipLoad: (payload: IRelationshipLoad) => payload,
    relationshipLoaded: (payload: IGetRelationshipModel) => payload,
    relationshipSave: (payload: RelationshipModel) => payload,
    relationshipSaved: (payload: RelationshipModel) => payload,
    relationshipLoadAll: (payload: RelationshipModel["type"]) => payload,
    relationshipLoadedAll: (payload: IGetRelationshipsModel) => payload,
    relationshipLoadedRelationship: (payload: IGetRelationshipSummary) => payload,
    relationshipLoadedEntity: (payload: IGetEntitySummary) => payload,
    relationshipLoadedPortfolio: (payload: PortfolioSummaryModel) => payload,

    relationshipCustomScreensLoaded: (payload: CustomScreenModel[]) => payload,
    relationshipCustomScreenDatasLoad: (payload: IRelationshipLoad) => payload,
    relationshipCustomScreenDatasLoaded: (payload: IGetCustomScreensDataModel) => payload,
    relationshipCustomScreenDataSubmit: (payload: IRelationshipsSubmitCustomScreenDataParameters) => payload,
    relationshipCustomScreenDataSubmitted: (payload: ICustomScreenDataModel) => payload,
    relationshipAddSecurityInDictionary: (payload: IGetSecuritySummary) => payload,

    relationshipMonitoringResultLoad: (payload: IMacroScriptsGetMonitoringResultForTargetParameters) => payload,
    relationshipMonitoringResultLoaded: (payload: IMonitoringResultsetModel) => payload,

    relationshipProcessExecutionsLoad: (payload: IRelationshipLoad) => payload,
    relationshipProcessExecutionsLoaded: (payload: IGetProcessExecutionsModel) => payload,

    relationshipDocumentsLoad: (payload: IRelationshipLoad) => payload,
    relationshipDocumentsLoaded: (payload: IGetDocumentsModel) => payload,
});

export interface IState {
    allLoading: boolean;
    customScreenDataSubmitting: boolean;
    customScreenDataLoading: boolean;
    loading: boolean;
    saving: boolean;
    deleting: boolean;
    all: (RelationshipSummaryModel | RelationshipModel)[];
    current?: RelationshipModel;
    dictionaries: GetAllSummaries & {
        customScreens: Record<string | number, CustomScreenSummaryModel>;
    }
    customScreens?: CustomScreenModel[];
    customScreenDatas?: ICustomScreenDataModel[];

    monitoringResults: Record<number, IMonitoringResultsetModel | null>;

    processExecutions?: ProcessExecutionSummaryModel[];
    processExecutionsLoading: boolean;

    documents?: IDocumentSummaryModel[];
    documentsLoading: boolean;
}

export const reducer = (
    state: IState = {
        customScreenDataSubmitting: false,
        customScreenDataLoading: false,
        allLoading: false,
        loading: false,
        deleting: false,
        saving: false,
        all: [],
        dictionaries: {
            entities: {},
            relationships: {},
            portfolios: {},
            customScreens: {},
            securities: {},
            transactions: {},
            cashMovements: {}
        },

        monitoringResults: {},
        processExecutionsLoading: false,

        documentsLoading: false
    },
    action: AnyActionOf<typeof ActionFactories>
) => produce(state, draft => {
    switch (action.type) {
        case "relationshipDocumentsLoad":
            draft.documentsLoading = true;
            break;
        case "relationshipDocumentsLoaded":
            draft.documentsLoading = false;
            draft.documents = action.payload.documents;
            draft.dictionaries.entities = { ...draft.dictionaries.entities, ...action.payload.entities };
            draft.dictionaries.securities = { ...draft.dictionaries.securities, ...action.payload.securities };
            draft.dictionaries.portfolios = { ...draft.dictionaries.portfolios, ...action.payload.portfolios };
            draft.dictionaries.relationships = { ...draft.dictionaries.relationships, ...action.payload.relationships };
            draft.dictionaries.cashMovements = { ...draft.dictionaries.cashMovements, ...action.payload.cashMovements };
            draft.dictionaries.transactions = { ...draft.dictionaries.transactions, ...action.payload.transactions };
            break;

        case "relationshipProcessExecutionsLoad":
            draft.processExecutionsLoading = true;
            break;
        case "relationshipProcessExecutionsLoaded":
            draft.processExecutionsLoading = false;
            draft.processExecutions = action.payload.processExecutions;
            draft.dictionaries.entities = { ...draft.dictionaries.entities, ...action.payload.entities };
            draft.dictionaries.relationships = { ...draft.dictionaries.relationships, ...action.payload.relationships };
            break;
        case "relationshipMonitoringResultLoad":
            draft.monitoringResults[action.payload.id] = null;
            break;
        case "relationshipMonitoringResultLoaded":
            draft.monitoringResults[action.payload.monitoringMacroId] = action.payload;
            break;

        case "relationshipCustomScreensLoaded":
            draft.customScreens = action.payload;
            break;
        case "relationshipCustomScreenDatasLoad":
            draft.customScreenDataLoading = true;
            break;
        case "relationshipCustomScreenDatasLoaded":
            draft.customScreenDataLoading = false;
            draft.dictionaries.entities = { ...draft.dictionaries.entities, ...action.payload.entities };
            draft.dictionaries.customScreens = { ...draft.dictionaries.customScreens, ...action.payload.customScreens };
            draft.customScreenDatas = action.payload.customScreenDatas;
            break;
        case "relationshipCustomScreenDataSubmit":
            draft.customScreenDataSubmitting = true;
            break;
        case "relationshipCustomScreenDataSubmitted":
            if (!draft.customScreenDatas) {
                draft.customScreenDatas = [];
            }
            const customScreenData = draft.customScreenDatas.find(i => i.customScreenId === action.payload.customScreenId);
            if (!!customScreenData) {
                Object.assign(customScreenData, action.payload);
            }
            else {
                draft.customScreenDatas.push(action.payload);
            }
            draft.customScreenDataSubmitting = false;
            break;
        case "relationshipLoadedRelationship":
            draft.dictionaries.relationships[action.payload.relationship.id] = action.payload.relationship;
            draft.dictionaries.entities = { ...draft.dictionaries.entities, ...action.payload.entities };
            break;
        case "relationshipLoadedEntity":
            draft.dictionaries.entities[action.payload.entity.id] = action.payload.entity;
            draft.dictionaries.entities = { ...draft.dictionaries.entities, ...action.payload.entities };
            break;
        case "relationshipLoadedPortfolio":
            draft.dictionaries.portfolios[action.payload.id] = action.payload;
            break;
        case "relationshipLoadAll":
            draft.allLoading = true;
            draft.dictionaries = {
                entities: {},
                relationships: {},
                portfolios: {},
                customScreens: {},
                securities: {},
                transactions: {},
                cashMovements: {}
            };
            break;
        case "relationshipLoadedAll":
            draft.allLoading = false;
            draft.all = action.payload.all;
            draft.dictionaries.entities = { ...draft.dictionaries.entities, ...action.payload.entities };
            break;
        case "relationshipLoad":
            delete draft.current;
            draft.customScreens = undefined;
            draft.customScreenDatas = undefined;
            draft.loading = true;
            draft.monitoringResults = {};
            draft.documents = undefined;
            draft.processExecutions = undefined;
            break;
        case "relationshipLoaded":
            draft.loading = false;
            draft.dictionaries.entities = { ...draft.dictionaries.entities, ...action.payload.entities };
            draft.dictionaries.portfolios = { ...draft.dictionaries.portfolios, ...action.payload.portfolios };
            draft.dictionaries.securities = { ...draft.dictionaries.securities, ...action.payload.securities };
            draft.dictionaries.relationships = { ...draft.dictionaries.relationships, ...action.payload.relationships };
            draft.current = action.payload.relationship;
            break;
        case "relationshipSave":
            draft.saving = true;
            break;
        case "relationshipSaved":
            draft.saving = false;
            draft.current = action.payload;
            const existing = draft.all.find(i => i.id === action.payload.id);
            if (existing) {
                Object.assign(existing, action.payload);
            }
            else {
                draft.all.push(action.payload);
            }
            break;
        case "relationshipDeleteCurrent":
            draft.deleting = true;
            break;
        case "relationshipDeleted":
            draft.deleting = false;
            const deletedId = action.payload;
            if (draft.current && draft.current.id === deletedId) {
                delete draft.current;
            }
            const idx = draft.all.findIndex(i => i.id === deletedId);
            if (idx >= 0) {
                draft.all.splice(idx, 1);
            }
            break;
    }
});
