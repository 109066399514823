import * as React from "react";
// import { Editor, ControlledEditorOnChange, EditorDidMount, monaco as reactMonaco } from "@monaco-editor/react";
import Editor, { OnChange, OnMount } from "@monaco-editor/react";
import { useDebounce } from "tools/lib/useDebounce";
import { ICompletionDataModel, ITextModelWithPosition } from "proxy/apiProxy";


// https://microsoft.github.io/monaco-editor/playground.html#interacting-with-the-editor-adding-an-action-to-an-editor-instance

export interface ICodeEditorError {
    start: number;
    length: number;
    startLineNumber: number;
    startColumnNumber: number;
    endLineNumber: number;
    endColumnNumber: number;
    sourcePart: string;
    message: string;
}
export interface ICodeEditorProps {
    onCodeChanged: (script: string) => void;
    onSave?: () => void;
    codeErrors?: ICodeEditorError[];
    jsonSchema?: object | string;
    code?: string;
    readOnly?: boolean;
    language: string;
    onRequestAutoComplete?: (position: ITextModelWithPosition) => Promise<ICompletionDataModel[]>;
    debounce?: boolean;
};

export default function CodeEditor({
    onCodeChanged,
    onSave,
    codeErrors,
    jsonSchema,
    readOnly = false,
    code,
    language,
    debounce = true,
    onRequestAutoComplete
}: ICodeEditorProps) {
    const monacoRef = React.useRef<typeof monaco>();
    const modelRef = React.useRef<monaco.editor.ITextModel | null | undefined>();
    const handleChangeDebounced = useDebounce<string>((newCode) => { new Promise(() => onCodeChanged(newCode)); }, 2000);
    const handleChange: OnChange = React.useCallback((newValue, ev) => {
        if (debounce) {
            // if (0) 
            handleChangeDebounced(newValue || "");
        }
        else {
            // if (0) 
            onCodeChanged(newValue || "");
        }
    }, [debounce, handleChangeDebounced, onCodeChanged]);
    // React.useEffect(() => {
    //     if (onRequestAutoComplete) {
    //         if(!monacoRef.current) return;
    //         monacoRef.current.languages.registerCompletionItemProvider(language, {
    //             provideCompletionItems: async function (model, position, context, token) {
    //                 // context.
    //                 const items=await  onRequestAutoComplete({
    //                     text: model.getValue(),
    //                     lineNumber: position.lineNumber,
    //                     column: position.column
    //                 });
    //                 //TODO: DO INTELLISENSE HERE
    //                 const suggestions:monaco.languages.CompletionItem[]=items.map(()=>({
    //                     // kind:

    //                 }));
    //                 const result: monaco.languages.CompletionList = { suggestions };
    //                 return result;
    //             }
    //         })
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [language, onRequestAutoComplete, monacoRef.current]);
    React.useEffect(() => { setErrors(codeErrors ?? []); }, [codeErrors]);
    React.useEffect(() => { setJsonSchema(jsonSchema); }, [jsonSchema]);

    const handleEditorMounted: OnMount = (editor, mnco) => {
        monacoRef.current = mnco;
        if (!monacoRef.current) return;
        modelRef.current = editor.getModel();
        // this.model?.getValue()
        editor.addAction({
            id: "fp-on-save",
            label: "Save",
            // tslint:disable-next-line: no-bitwise
            keybindings: [monacoRef.current.KeyMod.CtrlCmd | monacoRef.current.KeyCode.KeyS],
            run: () => {
                if (onSave) {
                    onSave();
                }
            }
        });
        if (codeErrors) {
            setErrors(codeErrors);
        }
        if (jsonSchema) {
            setJsonSchema(jsonSchema);
        }
    };

    const setJsonSchema = (batchSchema: any) => {
        const model = modelRef.current;
        if (!model || !batchSchema) {
            return;
        }
        if (!monacoRef.current) return;
        monacoRef.current.languages.json.jsonDefaults.setDiagnosticsOptions({
            validate: true,
            allowComments: true,
            schemas: [{
                uri: "http://myserver/foo-schema.json", // id of the first schema
                fileMatch: [model.uri.toString()], // associate with our model
                schema: typeof batchSchema === "string" ? JSON.parse(batchSchema) : batchSchema
            }]
        });
    }
    const setErrors = (scriptErrors: ICodeEditorError[]) => {
        const model = modelRef.current;
        if (!model) {
            return;
        }
        if (!monacoRef.current) return;
        monacoRef.current.editor.setModelMarkers(model, "MacroScriptEditor", scriptErrors.map(({ endColumnNumber, endLineNumber, startColumnNumber, startLineNumber, message }) => ({
            startLineNumber,
            startColumn: startColumnNumber,
            endLineNumber,
            endColumn: endColumnNumber,
            message,
            severity: monacoRef.current!.MarkerSeverity.Error,
        })));
    }

    // macroScriptsApi.getAutoCompleteScriptAsync({ textModel: { position: 1, text: "qzert" }, type: IMacroScriptTypeModel.Monitoring });

    return (<Editor
        options={{ readOnly }}
        onChange={handleChange}
        height="calc(100% - 30px)" // TODO: not great... possible to find much better
        value={code}
        language={language}
        onMount={handleEditorMounted}
    />);
}
