import * as React from "react";
import { DevelopmentItemModel, IClassificationTypeModel, IEntityMetadataModel } from "proxy/apiProxy";
import { fileToBase64, oProps } from "tools/lib/utility";
import FormTextField from "tools/fieldComponents/FormTextField";
import { FormControlLabel, Grid, Switch } from "@material-ui/core";
import ClassificationsData from "components/global/ClassificationsData";
import FormSimpleSelectField from "tools/fieldComponents/FormSimpleSelectField";
import { useReduxActions, useReduxSelections } from "tools/lib/reduxStoreAccess";
import DropZone from "tools/components/DropZone";
import { ColumnMappingProperties } from "./ColumnMappingProperties";

export interface IDevelopmentItemPropertiesProps {
    showEtlCodeGeneratorSection?: boolean;
    classificationTypes: IClassificationTypeModel[];
    onTypeChanged: (type: DevelopmentItemModel["type"]) => void;
    metadata?: Record<string | number, IEntityMetadataModel>;
}

export const types: Record<DevelopmentItemModel["type"], string> = {
    "QuestionnaireDevelopmentItemModel": "Questionnaire",
    "EtlMacroDevelopmentItemModel": "Etl Macro",
    "DashboardLayoutDevelopmentItemModel": "Dashboard Layout",
    // "FileConnectorDevelopmentItemModel": "File Connector",
    // "FileConsumerMacroDevelopmentItemModel": "File Consumer Macro",
    // "FileProducerMacroDevelopmentItemModel": "File Producer Macro",
    // "PipelineTaskDevelopmentItemModel": "Pipeline Task",
    "ReportTemplateDevelopmentItemModel": "Report Template",
    "DatasetMacroDevelopmentItemModel": "Dataset"
}

export function DevelopmentItemProperties({ classificationTypes, onTypeChanged, showEtlCodeGeneratorSection, metadata }: IDevelopmentItemPropertiesProps) {
    const { csvMapping } = useReduxSelections("developmentItem");
    const { mappingGenerate, codeGenerate } = useReduxActions("developmentItem");

    const handleTypeChanged = React.useCallback((value: string | number | undefined) =>
        onTypeChanged(value as DevelopmentItemModel["type"]), [onTypeChanged]);

    const [generateCodeFromMapping, setGenerateCodeFromMapping] = React.useState(csvMapping ? true : false);
    const handleGenerateCodeFromMapping = React.useCallback((_event: any, checked: boolean) => {
        setGenerateCodeFromMapping(checked);
    }, []);

    const handleFileOpen = async (file: File) => {
        const openedFile = await fileToBase64(file);
        mappingGenerate({
            data: openedFile.content,
            mimeType: openedFile.mimeType,
            name: openedFile.fileName
        });
    };

    return <Grid container={true} spacing={1} style={{ margin: 16 }}>
        <Grid item={true} sm={12} md={6} lg={4}>
            <FormSimpleSelectField name={oProps<DevelopmentItemModel>().path("type")} label="Type" options={types} onSelectionChanged={handleTypeChanged} required />
        </Grid>
        <Grid item={true} sm={12} md={6} lg={4}>
            <FormTextField name={oProps<DevelopmentItemModel>().path("name")} label="Name" required={true} />
        </Grid>
        <Grid item={true} sm={12} md={12} lg={12}>
            <ClassificationsData
                panelTitle="Classifications"
                fieldName={oProps<DevelopmentItemModel>().path("classifications")}
                classificationTypes={classificationTypes} />
        </Grid>
        {showEtlCodeGeneratorSection &&
            <Grid container={true} spacing={1}>
                {<Grid item={true} sm={12} md={12} lg={12}>
                    <FormControlLabel
                        control={<Switch checked={generateCodeFromMapping} onChange={handleGenerateCodeFromMapping} />}
                        label="Generate code from mapping"
                    />
                </Grid>}
                {!csvMapping && generateCodeFromMapping &&
                    <Grid item={true} sm={12} md={6} lg={3}>
                        <DropZone
                            emptyLabel={"Drop the file to map here"}
                            onFileOpen={handleFileOpen}
                        />
                    </Grid>}
                {csvMapping && generateCodeFromMapping &&
                    <Grid item={true} sm={12} md={12} lg={12}>
                        <ColumnMappingProperties
                            csvMapping={csvMapping}
                            metadata={metadata ?? {}}
                            handleCodeGenerate={codeGenerate}
                        />
                    </Grid>}
            </Grid>}
    </Grid>;
}
