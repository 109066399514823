import { Button, TableHead, TableCell, TableBody, Grid, Table, TableRow } from "@material-ui/core";
import { FormikHelpers, Formik, FormikProps, useField, FieldArray } from "formik";
import { ICsvMappingModel, IEntityMetadataModel, IColumnType, IColumnMappingModel } from "proxy/apiProxy";
import FormSimpleSelectField from "tools/fieldComponents/FormSimpleSelectField";
import FormSwitchField from "tools/fieldComponents/FormSwitchField";
import FormTextField from "tools/fieldComponents/FormTextField";
import { oProps } from "tools/lib/utility";

export interface IColumnMappingModelPropertiesProps {
    csvMapping: ICsvMappingModel;
    metadata: Record<string | number, IEntityMetadataModel>;
    handleCodeGenerate: (mapping: ICsvMappingModel) => void;
}

export function ColumnMappingProperties({ csvMapping, metadata, handleCodeGenerate }: IColumnMappingModelPropertiesProps) {
    const sourceTypeOptions = {
        [IColumnType.String]: "String",
        [IColumnType.Double]: "Double",
        [IColumnType.Int]: "Integer",
        [IColumnType.Date]: "Date",
        [IColumnType.Boolean]: "Boolean"
    };

    const targetTableOptions = metadata ? Object.values(metadata).reduce((acc, table) => ({ ...acc, [table.name]: table.name }), {}) : {};

    const getProperties = (tableName: string | undefined, metadata: Record<string | number, IEntityMetadataModel>): any => {
        if (!tableName || !metadata[tableName]) return {};

        const properties = Object.values(metadata[tableName].properties)
            .reduce((acc, property) => ({ ...acc, [property.name]: property.name }), {})

        if (metadata[tableName].inherits) {
            return {
                ...properties,
                ...getProperties(metadata[tableName].inherits, metadata)
            };
        }

        return properties;
    };


    const targetPropertyOptions = (columnMapping: IColumnMappingModel) => {
        if (!columnMapping.targetProperty?.tableName) return {};
        return getProperties(columnMapping.targetProperty.tableName, metadata);
    };

    const handleSubmit = (values: ICsvMappingModel, { setSubmitting }: FormikHelpers<ICsvMappingModel>) => {
        handleCodeGenerate(values)
        setSubmitting(false);
    }

    return <Formik onSubmit={handleSubmit} initialValues={csvMapping} enableReinitialize={true} >{renderForm}</Formik>;

    function renderForm({ dirty, isValid, submitForm, values }: FormikProps<ICsvMappingModel>) {
        return <Grid container={true} spacing={5}>
            <Grid item={true} sm={12}>
                <Button onClick={submitForm} disabled={!values} variant="contained" color="primary">Generate code</Button>
            </Grid>
            <Grid item={true} sm={12}>
                <ColumnMappingRows
                    name={oProps<ICsvMappingModel>().path("columns")}
                    sourceTypeOptions={sourceTypeOptions}
                    targetTableOptions={targetTableOptions}
                    targetPropertyOptions={targetPropertyOptions}
                />
            </Grid>
        </Grid>;
    }
}

interface IColumnMappingRowsProps {
    name: string;
    sourceTypeOptions: Record<IColumnType, string>;
    targetTableOptions: Record<string, string>;
    targetPropertyOptions: (columnMapping: IColumnMappingModel) => Record<string, string>;
}

function ColumnMappingRows({ name, sourceTypeOptions, targetTableOptions, targetPropertyOptions }: IColumnMappingRowsProps) {
    const [, { value: columMappings },] = useField<IColumnMappingModel[]>(name);
    return <FieldArray name={name} render={renderColumnMapping} validateOnChange={false} />;

    function renderColumnMapping(): React.ReactNode {
        const style = { paddingTop: 0, paddingBottom: 0, borderBottom: 'none' };
        return <Table>
            <TableHead>
                <TableRow>
                    <TableCell size="small" style={style}>Source Column</TableCell>
                    <TableCell size="small" style={style}>Source Type</TableCell>
                    <TableCell size="small" style={{ ...style, maxWidth: 200 }}>Format</TableCell>
                    <TableCell size="small" style={{ ...style, maxWidth: 100 }}>Optional</TableCell>
                    <TableCell size="small" style={style}>Target Table</TableCell>
                    <TableCell size="small" style={style}>Target Property</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {columMappings.map((columnMapping, idx) =>
                    <ColumnMappingRow
                        name={oProps<IColumnMappingModel[]>(name).path(idx)}
                        columnMapping={columnMapping}
                        index={idx}
                        sourceTypeOptions={sourceTypeOptions}
                        targetTableOptions={targetTableOptions}
                        targetPropertyOptions={targetPropertyOptions(columnMapping)}
                    />)
                }
            </TableBody>
        </Table>;
    }
}

interface IColumnMappingRowProps {
    name: string;
    columnMapping: IColumnMappingModel;
    index: number;
    sourceTypeOptions: Record<IColumnType, string>;
    targetTableOptions: Record<string, string>;
    targetPropertyOptions: Record<string, string>;
}

function ColumnMappingRow({ name, columnMapping, sourceTypeOptions, targetTableOptions, targetPropertyOptions }: IColumnMappingRowProps) {
    const style = { paddingTop: 0, paddingBottom: 0, borderBottom: 'none' };
    return <TableRow style={{ borderBottom: 'none' }}>
        <TableCell size="small" style={style}>
            <FormTextField
                name={oProps<IColumnMappingModel>(name).path("sourceColumn", "name")}
                disabled
            />
        </TableCell>
        <TableCell size="small" style={style}>
            <FormSimpleSelectField
                name={oProps<IColumnMappingModel>(name).path("sourceColumn", "type", "type")}
                options={sourceTypeOptions}
            />
        </TableCell>
        <TableCell size="small" style={{ ...style, maxWidth: 200 }}>
            {columnMapping.sourceColumn.type.type === IColumnType.Date && (
                <FormTextField
                    name={oProps<IColumnMappingModel>(name).path("sourceColumn", "type", "format")}
                    label="Date Format (e.g. yyyy-MM-dd)"
                />
            )}
            {columnMapping.sourceColumn.type.type === IColumnType.Boolean && (
                <Grid container={true} spacing={1}>
                    <Grid item={true} xs={6}>
                        <FormTextField
                            name={oProps<IColumnMappingModel>(name).path("sourceColumn", "type", "trueValue")}
                            label="True value"
                        />
                    </Grid>
                    <Grid item={true} xs={6}>
                        <FormTextField
                            name={oProps<IColumnMappingModel>(name).path("sourceColumn", "type", "falseValue")}
                            label="False value"
                        />
                    </Grid>
                </Grid>
            )}
            <Grid container={true} spacing={1}>
                {(columnMapping.sourceColumn.type.type === IColumnType.Int || columnMapping.sourceColumn.type.type === IColumnType.Double) && (
                    <Grid item={true} xs={6}>
                        <FormTextField
                            name={oProps<IColumnMappingModel>(name).path("sourceColumn", "type", "groupSeparator")}
                            label="Group Separator"
                        />
                    </Grid>
                )}
                {columnMapping.sourceColumn.type.type === IColumnType.Double && (
                    <Grid item={true} xs={6}>
                        <FormTextField
                            name={oProps<IColumnMappingModel>(name).path("sourceColumn", "type", "decimalSeparator")}
                            label="Decimal Separator"
                        />
                    </Grid>
                )}
            </Grid>
        </TableCell>
        <TableCell size="small" style={{ ...style, maxWidth: 100 }}>
            <FormSwitchField
                name={oProps<IColumnMappingModel>(name).path("sourceColumn", "isOptional")}
                label="Optional"
            />
        </TableCell>
        <TableCell size="small" style={style}>
            <FormSimpleSelectField
                name={oProps<IColumnMappingModel>(name).path("targetProperty", "tableName")}
                options={targetTableOptions}
            />
        </TableCell>
        <TableCell size="small" style={style}>
            <FormSimpleSelectField
                name={oProps<IColumnMappingModel>(name).path("targetProperty", "propertyName")}
                options={targetPropertyOptions}
                disabled={!columnMapping.targetProperty?.tableName}
            />
        </TableCell>
    </TableRow>;
}
