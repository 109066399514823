import produce from "immer";
import {
    ISicavModel, IGetSicavModel, IGetSicavsModel, ISicavSummaryModel, CustomScreenModel,
    CustomScreenSummaryModel, ICustomScreenDataModel, IGetCustomScreensDataModel, ISecuritiesSubmitCustomScreenDataParameters,
    IMonitoringResultsetModel, IMacroScriptsGetMonitoringResultForTargetParameters, ProcessExecutionSummaryModel, IGetProcessExecutionsModel, 
    IGetDocumentsModel, IDocumentSummaryModel
} from "proxy/apiProxy";
import { produceActionFactories, AnyActionOf } from "tools/lib/store";
import { IBase64File } from "tools/lib/utility";
import { IGetEntitySummary } from "../Reference/slice";
import { GetAllSummaries } from "features/Document/utils";

export interface ISicavData {
    sicav: ISicavModel;
    imageFile?: IBase64File;
}

export interface IState {
    sicavAllLoading: boolean;
    sicavLoading: boolean;
    sicavs: (ISicavSummaryModel | ISicavModel)[];
    sicavCurrent?: ISicavModel;
    sicavSaving: boolean;
    sicavDeleting: boolean;
    dictionaries: GetAllSummaries & {
        customScreens: Record<string | number, CustomScreenSummaryModel>;
    }
    entityPicture?: IBase64File;
    customScreens?: CustomScreenModel[];
    customScreenDatas?: ICustomScreenDataModel[];
    customScreenDataSubmitting: boolean;
    customScreenDataLoading: boolean;
    monitoringResults: Record<number, IMonitoringResultsetModel | null>;
    processExecutions?: ProcessExecutionSummaryModel[];
    processExecutionsLoading: boolean;
    documents?: IDocumentSummaryModel[];
    documentsLoading: boolean;
}

export interface ISicavLoadedPayload extends IGetSicavModel {
    entityPicture?: IBase64File;
}

export const ActionFactories = produceActionFactories({
    managedSicavLoad: (payload: ISicavModel["id"]) => payload,

    managedSicavLoaded: (payload: ISicavLoadedPayload) => payload,

    managedSicavSave: (payload: ISicavData) => payload,
    managedSicavSaved: (payload: ISicavModel) => payload,
    managedSicavDelete: (payload: ISicavModel["id"]) => payload,
    managedSicavDeleted: (payload: ISicavModel["id"]) => payload,
    managedSicavLoadAll: () => undefined,
    managedSicavLoadedAll: (payload: IGetSicavsModel) => payload,
    managedSicavAddEntityInDictionary: (payload: IGetEntitySummary) => payload,
    managedSicavCustomScreensLoaded: (payload: CustomScreenModel[]) => payload,
    managedSicavCustomScreenDatasLoad: (payload: ISicavModel["id"]) => payload,
    managedSicavCustomScreenDatasLoaded: (payload: IGetCustomScreensDataModel) => payload,
    managedSicavCustomScreenDataSubmit: (payload: ISecuritiesSubmitCustomScreenDataParameters) => payload,
    managedSicavCustomScreenDataSubmitted: (payload: ICustomScreenDataModel) => payload,

    managedSicavMonitoringResultLoad: (payload: IMacroScriptsGetMonitoringResultForTargetParameters) => payload,
    managedSicavMonitoringResultLoaded: (payload: IMonitoringResultsetModel) => payload,
    managedSicavProcessExecutionsLoad: (payload: number) => payload,
    managedSicavProcessExecutionsLoaded: (payload: IGetProcessExecutionsModel) => payload,

    managedSicavDocumentsLoad: (payload: number) => payload,
    managedSicavDocumentsLoaded: (payload: IGetDocumentsModel) => payload,
});

export function reducer(
    state: IState = {
        sicavLoading: false,
        sicavAllLoading: false,
        sicavSaving: false,
        sicavDeleting: false,
        sicavs: [],
        dictionaries: {
            entities: {},
            customScreens: {},
            relationships: {},
            portfolios: {},
            securities: {},
            transactions: {},
            cashMovements: {},
        },
        customScreenDataLoading: false,
        customScreenDataSubmitting: false,
        monitoringResults: {},
        processExecutionsLoading: false,
        documentsLoading: false,
    },
    action: AnyActionOf<typeof ActionFactories>
): IState {
    return produce(state, draft => {
        switch (action.type) {
            case "managedSicavProcessExecutionsLoad":
                draft.processExecutionsLoading = true;
                break;
            case "managedSicavProcessExecutionsLoaded":
                draft.processExecutionsLoading = false;
                draft.processExecutions = action.payload.processExecutions;
                draft.dictionaries.entities = { ...draft.dictionaries.entities, ...action.payload.entities };
                draft.dictionaries.relationships = { ...draft.dictionaries.relationships, ...action.payload.relationships };
                break;

            case "managedSicavDocumentsLoad":
                draft.documentsLoading = true;
                break;
            case "managedSicavDocumentsLoaded":
                draft.documentsLoading = false;
                draft.documents = action.payload.documents;
                draft.dictionaries.entities = { ...draft.dictionaries.entities, ...action.payload.entities };
                draft.dictionaries.relationships = { ...draft.dictionaries.relationships, ...action.payload.relationships };
                draft.dictionaries.cashMovements = { ...draft.dictionaries.cashMovements, ...action.payload.cashMovements };
                draft.dictionaries.transactions = { ...draft.dictionaries.transactions, ...action.payload.transactions };
                draft.dictionaries.securities = { ...draft.dictionaries.securities, ...action.payload.securities };
                draft.dictionaries.portfolios = { ...draft.dictionaries.portfolios, ...action.payload.portfolios };
                break;

            case "managedSicavMonitoringResultLoad":
                draft.monitoringResults[action.payload.id] = null;
                break;
            case "managedSicavMonitoringResultLoaded":
                draft.monitoringResults[action.payload.monitoringMacroId] = action.payload;
                break;

            case "managedSicavCustomScreensLoaded":
                draft.customScreens = action.payload;
                break;
            case "managedSicavCustomScreenDatasLoad":
                draft.customScreenDataLoading = true;
                break;
            case "managedSicavCustomScreenDatasLoaded":
                draft.customScreenDataLoading = false;
                draft.dictionaries.entities = { ...draft.dictionaries.entities, ...action.payload.entities };
                draft.dictionaries.customScreens = { ...draft.dictionaries.customScreens, ...action.payload.customScreens };
                draft.customScreenDatas = action.payload.customScreenDatas;
                break;
            case "managedSicavCustomScreenDataSubmit":
                draft.customScreenDataSubmitting = true;
                break;
            case "managedSicavCustomScreenDataSubmitted":
                if (!draft.customScreenDatas) {
                    draft.customScreenDatas = [];
                }
                const customScreenData = draft.customScreenDatas.find(i => i.customScreenId === action.payload.customScreenId);
                if (!!customScreenData) {
                    Object.assign(customScreenData, action.payload);
                }
                else {
                    draft.customScreenDatas.push(action.payload);
                }
                draft.customScreenDataSubmitting = false;
                break;

            case "managedSicavLoadAll":
                draft.sicavAllLoading = true;
                break;
            case "managedSicavLoadedAll":
                {
                    draft.sicavAllLoading = false;
                    const { all, ...dictionaries } = action.payload;
                    draft.sicavs = all;
                    draft.dictionaries = { ...draft.dictionaries, ...dictionaries };
                }
                break;
            case "managedSicavAddEntityInDictionary":
                draft.dictionaries.entities[action.payload.entity.id] = action.payload.entity;
                draft.dictionaries.entities = { ...draft.dictionaries.entities, ...action.payload.entities };
                break;
            case "managedSicavLoad":
                draft.sicavLoading = true;
                draft.sicavCurrent = undefined;
                draft.customScreens = undefined;
                draft.customScreenDatas = undefined;
                draft.monitoringResults = {};
                draft.documents = undefined;
                draft.processExecutions = undefined;
                break;
            case "managedSicavLoaded":
                {
                    draft.sicavLoading = false;
                    const { sicav, entityPicture, ...dictionaries } = action.payload;
                    draft.sicavCurrent = sicav;
                    draft.entityPicture = entityPicture;
                    draft.dictionaries.entities = { ...draft.dictionaries.entities, ...dictionaries.entities };
                }
                break;
            case "managedSicavSave":
                draft.sicavSaving = true;
                break;


            case "managedSicavSaved":
                draft.sicavSaving = false;
                const sicav = action.payload;
                draft.sicavCurrent = sicav;
                const existing = draft.sicavs.find(i => i.id === sicav.id);
                if (existing) {
                    Object.assign(existing, sicav);
                }
                else {
                    draft.sicavs.push(sicav);
                }
                break;
            case "managedSicavDelete":
                draft.sicavDeleting = true;
                break;
            case "managedSicavDeleted":
                draft.sicavDeleting = false;
                const deletedId = action.payload;
                if (draft.sicavCurrent?.id === deletedId) {
                    delete draft.sicavCurrent;
                }
                const idx = draft.sicavs.findIndex(i => i.id === deletedId);
                if (idx >= 0) {
                    draft.sicavs.splice(idx, 1);
                }
                break;
        }
    });
}
